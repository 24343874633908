<!-- eslint-disable max-len -->
<template>
  <div class="menu_area">
    <div class="member_rank">
      <img class="rank_pic" src="@/assets/img/account/level_img1.svg" alt="">
      <h6 class="rank_name txt-bold txt-blue">一般會員</h6>
      <div class="hr_line"></div>
      <h4 class="user_name">
        <span class="txt-bold">
          {{user.name || '使用者'}},&nbsp;
        </span>
        早安&nbsp;
        <img src="@/assets/img/icon/icon_exclamation.svg" alt="" style="width: .65em;">
      </h4>
    </div>
    <div class="member_links">
      <div
        class="rwd_openlinks txt-center"
        @click="toggleMobileNavShow();">
          <div class="show_title">
            <img
              v-if="$route.meta == '會員資料'"
              src="@/assets/img/account/icon_menu_1.svg" alt="">
            <img
              v-if="$route.meta == '修改密碼'"
              src="@/assets/img/account/icon_menu_2.svg" alt="">
            <img
              v-if="$route.meta == '訂單查詢'"
              src="@/assets/img/account/icon_menu_3.svg" alt="">
            <img
              v-if="$route.meta == '追蹤清單'"
              src="@/assets/img/account/icon_menu_4.svg" alt="">
            <img
              v-if="$route.meta == '我的優惠'"
              src="@/assets/img/account/icon_menu_5.svg" alt="">
            <img
              v-if="$route.meta == '我的通知'"
              src="@/assets/img/account/icon_menu_6.svg" alt="">
            <h4 class="txt-bold">{{$route.meta}}</h4>
          </div>
          <img src="@/assets/img/icon/icon_down-g.svg" alt="">
      </div>
      <div class="rwd_linkbox" :class="{active: mobileNavShow}">
        <router-link
          :to="{ name: 'MemberData' }"
          class="link_item">
            <span>
              <svg xmlns="http://www.w3.org/2000/svg" width="16.946" height="19.148" viewBox="0 0 16.946 19.148">
                <g transform="translate(-736.611 -951.627)">
                  <!-- eslint-disable-next-line max-len -->
                  <path d="M737.111,970.775a.5.5,0,0,1-.5-.5v-3.818a4.27,4.27,0,0,1,4.52-3.95h7.939a4.262,4.262,0,0,1,4.485,3.913l0,3.855a.5.5,0,0,1-.5.5Zm15.946-.5h0Zm-12.18-6.775a3.267,3.267,0,0,0-3.268,3l0,3.28h14.946v-3.317a3.256,3.256,0,0,0-3.453-2.952H741.1C741.023,963.5,740.95,963.5,740.877,963.5Z" fill="#2c353e"/>
                  <!-- eslint-disable-next-line max-len -->
                  <path d="M745.085,960.97a4.671,4.671,0,1,1,4.671-4.672A4.677,4.677,0,0,1,745.085,960.97Zm0-8.343a3.671,3.671,0,1,0,3.671,3.671A3.676,3.676,0,0,0,745.085,952.627Z" fill="#2c353e"/>
                </g>
              </svg>
              <h6>會員資料</h6>
            </span>
        </router-link>
        <router-link
          :to="{ name: 'MemberModifyPassword' }"
          class="link_item">
            <span>
              <svg xmlns="http://www.w3.org/2000/svg" width="19" height="21" viewBox="0 0 19 21">
                <!-- eslint-disable-next-line max-len -->
                <path d="M752.111,1002.627h-1.5v-3.5a5.5,5.5,0,0,0-11,0v3.5h-1.5a2.5,2.5,0,0,0-2.5,2.5v7a2.5,2.5,0,0,0,2.5,2.5h14a2.5,2.5,0,0,0,2.5-2.5v-7A2.5,2.5,0,0,0,752.111,1002.627Zm-11.5-3.5a4.5,4.5,0,0,1,9,0v3.5h-9Zm13,13a1.5,1.5,0,0,1-1.5,1.5h-14a1.5,1.5,0,0,1-1.5-1.5v-7a1.5,1.5,0,0,1,1.5-1.5h14a1.5,1.5,0,0,1,1.5,1.5Z" transform="translate(-735.611 -993.627)" fill="#2c353e"/>
              </svg>
              <h6>修改密碼</h6>
            </span>
        </router-link>
        <router-link
          :to="{ name: 'MemberOrder' }"
          class="link_item">
            <span>
              <svg xmlns="http://www.w3.org/2000/svg" width="18" height="20" viewBox="0 0 18 20">
                <g transform="translate(-736.111 -1038.127)">
                  <g>
                    <rect id="Rectangle_1001" data-name="Rectangle 1001" width="18" height="20" transform="translate(736.112 1038.127)" fill="none"/>
                    <path id="Path_3233" data-name="Path 3233" d="M753.611,1058.127h-17a.5.5,0,0,1-.5-.5v-19a.5.5,0,0,1,.5-.5h17a.5.5,0,0,1,.5.5v19A.5.5,0,0,1,753.611,1058.127Zm-16.5-1h16v-18h-16Z" fill="#2c353e"/>
                  </g>
                  <path d="M750.8,1043.512h-7.048a.5.5,0,0,1,0-1H750.8a.5.5,0,0,1,0,1Z" fill="#2c353e"/>
                  <path d="M750.8,1048.512h-7.048a.5.5,0,0,1,0-1H750.8a.5.5,0,0,1,0,1Z" fill="#2c353e"/>
                  <path d="M750.8,1053.512h-7.048a.5.5,0,0,1,0-1H750.8a.5.5,0,0,1,0,1Z" fill="#2c353e"/>
                  <g>
                    <circle cx="1" cy="1" r="1" transform="translate(739.112 1042.127)" fill="none"/>
                    <path d="M740.111,1044.127a1,1,0,1,1,1-1A1,1,0,0,1,740.111,1044.127Z" fill="#2c353e"/>
                  </g>
                  <g>
                    <circle cx="1" cy="1" r="1" transform="translate(739.112 1047.127)" fill="none"/>
                    <path d="M740.111,1049.127a1,1,0,1,1,1-1A1,1,0,0,1,740.111,1049.127Z" fill="#2c353e"/>
                  </g>
                  <g>
                    <circle cx="1" cy="1" r="1" transform="translate(739.112 1052.127)" fill="none"/>
                    <path d="M740.111,1054.127a1,1,0,1,1,1-1A1,1,0,0,1,740.111,1054.127Z" fill="#2c353e"/>
                  </g>
                </g>
              </svg>
              <h6>訂單查詢</h6>
            </span>
        </router-link>
        <router-link
          :to="{ name: 'MemberTrack' }"
          class="link_item">
            <span>
              <svg xmlns="http://www.w3.org/2000/svg" width="18.587" height="17.07" viewBox="0 0 18.587 17.07">
                <g transform="translate(-736.111 -1082.127)">
                  <path d="M745.405,1099.2c-5-3.408-7.618-6.509-8.693-9.1-2.878-6.958,5.346-10.284,8.693-6.179,3.348-4.1,11.572-.78,8.693,6.175C753.023,1092.688,750.4,1095.788,745.405,1099.2Z" fill="none"/>
                  <path d="M745.405,1097.98c4.014-2.824,6.694-5.675,7.769-8.269a4.741,4.741,0,0,0-.138-4.667,4.52,4.52,0,0,0-3.746-1.917,3.924,3.924,0,0,0-3.11,1.423l-.775.951-.775-.951a3.924,3.924,0,0,0-3.11-1.423,4.522,4.522,0,0,0-3.746,1.917,4.74,4.74,0,0,0-.138,4.666c1.075,2.6,3.755,5.446,7.769,8.27m0,1.217c-5-3.408-7.618-6.509-8.693-9.1-2.018-4.874,1.419-7.966,4.808-7.966a4.93,4.93,0,0,1,3.885,1.791,4.933,4.933,0,0,1,3.885-1.791c3.389,0,6.826,3.092,4.808,7.966C753.023,1092.688,750.4,1095.789,745.405,1099.2Z" fill="#2c353e"/>
                </g>
              </svg>
              <h6>追蹤清單</h6>
              <!-- <p class="newmessage txt-white small">1</p> -->
            </span>
        </router-link>
        <router-link
          :to="{ name: 'MemberDiscount' }"
          class="link_item">
            <span>
              <svg xmlns="http://www.w3.org/2000/svg" width="19.799" height="19.132" viewBox="0 0 20.999 20.132">
                <g transform="translate(-734.612 -1122.624)">
                  <path d="M747.866,1142.756a3.9,3.9,0,0,1-1.554-.471,2.033,2.033,0,0,0-2.4,0,2.857,2.857,0,0,1-2.043.395,2.956,2.956,0,0,1-1.462-1.561,1.922,1.922,0,0,0-1.862-1.353,2.954,2.954,0,0,1-1.926-.9,2.917,2.917,0,0,1-.263-2.1,3.313,3.313,0,0,0,.005-1.234,3.234,3.234,0,0,0-.7-.946,2.244,2.244,0,0,1,0-3.791,3.211,3.211,0,0,0,.705-.951,3.308,3.308,0,0,0-.005-1.237,2.188,2.188,0,0,1,2.18-2.99,3.321,3.321,0,0,0,1.17-.374,3.272,3.272,0,0,0,.706-.988,2.932,2.932,0,0,1,1.456-1.556,2.857,2.857,0,0,1,2.046.4,2.03,2.03,0,0,0,2.4,0,2.85,2.85,0,0,1,2.043-.4,2.947,2.947,0,0,1,1.462,1.562,1.925,1.925,0,0,0,1.864,1.353,2.2,2.2,0,0,1,2.188,3,3.313,3.313,0,0,0-.005,1.234,3.227,3.227,0,0,0,.705.951,2.242,2.242,0,0,1,0,3.787,3.239,3.239,0,0,0-.7.95,3.353,3.353,0,0,0,0,1.238,2.188,2.188,0,0,1-2.179,2.99,3.331,3.331,0,0,0-1.169.374,3.248,3.248,0,0,0-.707.988,2.935,2.935,0,0,1-1.457,1.555A1.584,1.584,0,0,1,747.866,1142.756Zm-2.754-1.864a4.023,4.023,0,0,1,1.616.483,2.238,2.238,0,0,0,1.319.354c.346-.113.625-.611.894-1.093a3.9,3.9,0,0,1,.992-1.309,3.955,3.955,0,0,1,1.559-.545c.541-.109,1.1-.222,1.3-.506a2.317,2.317,0,0,0,.079-1.39,3.973,3.973,0,0,1,.038-1.66,3.851,3.851,0,0,1,.922-1.321c.381-.412.775-.839.775-1.215s-.394-.8-.775-1.214a3.844,3.844,0,0,1-.922-1.322,3.938,3.938,0,0,1-.037-1.656,2.307,2.307,0,0,0-.079-1.393c-.209-.287-.771-.4-1.314-.51a2.829,2.829,0,0,1-2.538-1.845c-.272-.484-.553-.986-.9-1.1a2.279,2.279,0,0,0-1.319.354,2.944,2.944,0,0,1-3.233,0,2.272,2.272,0,0,0-1.319-.354c-.346.113-.624.612-.894,1.093a3.885,3.885,0,0,1-.991,1.31,3.964,3.964,0,0,1-1.561.545c-.54.109-1.1.222-1.3.506a2.3,2.3,0,0,0-.078,1.39,3.983,3.983,0,0,1-.038,1.659,3.861,3.861,0,0,1-.923,1.322c-.381.413-.776.838-.776,1.214s.4.805.778,1.219a3.885,3.885,0,0,1,.92,1.314,3.957,3.957,0,0,1,.037,1.66,2.3,2.3,0,0,0,.079,1.392c.209.288.77.4,1.314.51a2.827,2.827,0,0,1,2.537,1.845c.273.485.554.987.9,1.1a2.294,2.294,0,0,0,1.319-.354A4.036,4.036,0,0,1,745.112,1140.892Z" fill="#2c353e"/>
                  <path d="M741.111,1137.4a.5.5,0,0,1-.353-.854l7.768-7.769a.5.5,0,0,1,.707.707l-7.768,7.769A.5.5,0,0,1,741.111,1137.4Z" fill="#2c353e"/>
                  <path d="M742.111,1132.127a2,2,0,1,1,2-2A2,2,0,0,1,742.111,1132.127Zm0-3a1,1,0,1,0,1,1A1,1,0,0,0,742.111,1129.127Z" fill="#2c353e"/>
                  <path d="M748.111,1138.127a2,2,0,1,1,2-2A2,2,0,0,1,748.111,1138.127Zm0-3a1,1,0,1,0,1,1A1,1,0,0,0,748.111,1135.127Z" fill="#2c353e"/>
                </g>
              </svg>
              <h6>我的優惠</h6>
              <!-- <p class="newmessage txt-white small">5</p> -->
            </span>
        </router-link>
        <router-link
          :to="{ name: 'MemberNotice' }"
          class="link_item">
            <span>
              <svg xmlns="http://www.w3.org/2000/svg" width="17.3" height="18.89" viewBox="0 0 17.3 18.89">
                <g transform="translate(-736.64 -1034.72)">
                  <g>
                    <path d="M740.17,1035.76a.5.5,0,0,0-.7,0,9.686,9.686,0,0,0-2.83,6.82l.01.07a.5.5,0,0,0,.49.43.508.508,0,0,0,.5-.5,8.668,8.668,0,0,1,2.53-6.12A.5.5,0,0,0,740.17,1035.76Zm10.95,0a.5.5,0,0,0-.7,0,.483.483,0,0,0,0,.7,8.658,8.658,0,0,1,2.52,6.12v.07a.551.551,0,0,0,.15.28.509.509,0,0,0,.35.15.5.5,0,0,0,.5-.5A9.634,9.634,0,0,0,751.12,1035.76Zm1.57,13.19a5.425,5.425,0,0,1-1.93-4.16v-2.21a5.47,5.47,0,0,0-4.88-5.44l-.09-.01v-1.91a.5.5,0,0,0-.5-.5.508.508,0,0,0-.5.5v1.91l-.09.01a5.478,5.478,0,0,0-4.88,5.44v2.21a5.41,5.41,0,0,1-1.93,4.16,1.262,1.262,0,0,0-.45.98,1.3,1.3,0,0,0,1.29,1.3h3.72l.01.08a2.909,2.909,0,0,0,2.83,2.3h.01a2.909,2.909,0,0,0,2.83-2.3l.01-.08h3.72a1.3,1.3,0,0,0,1.3-1.3A1.283,1.283,0,0,0,752.69,1048.95Zm-5.62,2.41a1.887,1.887,0,0,1-3.56,0l-.05-.13h3.66Zm4.78-1.13H738.73a.3.3,0,0,1-.19-.53,6.4,6.4,0,0,0,2.28-4.91v-2.21a4.475,4.475,0,1,1,8.95,0v2.21a6.4,6.4,0,0,0,2.28,4.92.287.287,0,0,1,.1.22A.3.3,0,0,1,751.85,1050.23Z" fill="#2c353e"/>
                  </g>
                </g>
              </svg>
              <h6>我的通知</h6>
              <p
                v-if="noticeUnread > 0"
                class="newmessage txt-white small">
                  {{noticeUnread}}
              </p>
            </span>
        </router-link>
      </div>
    </div>
  </div>
</template>

<script>
import { mapState } from 'vuex';
import requestApi from '@/lib/http';

export default {
  name: 'MemberSideNav',
  data() {
    return {
      mobileNavShow: false,
      noticeUnread: 0,
    };
  },
  computed: {
    ...mapState([
      'user',
    ]),
  },
  methods: {
    toggleMobileNavShow() {
      this.mobileNavShow = !this.mobileNavShow;
    },
    async getUnreadMsgCount() {
      const { data } = await requestApi('member.getUnreadMsgCount');
      this.noticeUnread = data;
    },
  },
  updated() {
    this.getUnreadMsgCount();
  },
};
</script>
