export default {
  /**
   * @name 登入
   */
  baseUrl: 'promotion',
  checkPromotionCode: {
    url: '/checkPromotionCode',
    method: 'post',
    token: false,
  },
  getMemberCoupons: {
    url: '/getMemberCoupons',
    method: 'post',
    token: false,
  },
  getMemberBirthdayCoupons: {
    url: '/getMemberBirthdayCoupons',
    method: 'post',
    token: false,
  },
  getProductPlusEvents: {
    url: '/getProductPlusEvents',
    token: false,
  },
  checkDiscountEvent: {
    url: '/checkDiscountEvent',
    method: 'post',
    token: false,
  },
  getMarketProduct: {
    url: '/getMarketProduct',
    token: false,
  },
  getMarketProductToken: {
    url: '/getMarketProduct',
    token: true,
  },
  checkFreightEvent: {
    url: '/checkFreightEvent',
    method: 'post',
    token: true,
  },
};
