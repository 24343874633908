<template>
  <div class="company_area">
    <Header/>

    <!-- kv輪播 -->
    <CompanyKv/>

    <section id="point">
      <div class="inner w1300">
        <div class="point_item txt-white">
          <p class="num txt-blackbold">
            100<span>%</span>
          </p>
          <h5 class="txt-bold">日本企業</h5>
        </div>
        <div class="point_item txt-white">
          <p class="num txt-blackbold">
            1974<span>年</span>
          </p>
          <h5 class="txt-bold">創立</h5>
        </div>
        <div class="point_item txt-white">
          <img src="@/assets/img/company/banner-bar_icon1.png" alt="">
          <h5 class="txt-bold">品質穩定</h5>
        </div>
        <div class="point_item txt-white">
          <img src="@/assets/img/company/banner-bar_icon2.png" alt="">
          <h5 class="txt-bold">多元方案</h5>
        </div>
        <div class="point_item txt-white">
          <img src="@/assets/img/company/banner-bar_icon3.png" alt="">
          <h5 class="txt-bold">急件預約</h5>
        </div>
        <div class="point_item txt-white">
          <img src="@/assets/img/company/banner-bar_icon4.png" alt="">
          <h5 class="txt-bold">出差節費</h5>
        </div>
      </div>
    </section>

    <!-- 最新消息 -->
    <PublicDiscountSection
      v-if="lastNews.length > 0 && show"
      :link="'/CompanyNews?category=&page=1'"
      :newsData="lastNews"/>

    <section id="y_block">
      <div
        class="bg"
        :style="`background-image: url(${require('@/assets/img/icon/deco_exclamation_big.svg')});`">
      </div>
      <div class="w1300">
        <h2
          v-scroll-reveal="$sr_rightSetting"
          class="h2-super_title txt-bold txt-center">
            企業的出差網路資費困擾<br>
            讓 <span class="txt-highbold txt-style-i">Wi-Ho!</span> 為您解決
            <img src="@/assets/img/icon/icon_exclamation.svg" alt="">
        </h2>
        <div class="btnbox w1300 txt-center">
          <button
            type="button"
            class="btn btn-purple_purple_line"
            @click="coopLightboxStatusChange(true)">
              <img src="@/assets/img/icon/icon_next-gl.svg" alt="">
              <p>立即聯繫</p>
          </button>
        </div>
      </div>
    </section>

    <section id="w_block">
      <div class="bg" style="background-image: url(/img/icon/deco_exclamation_big.svg);"></div>
      <div class="w1300">
        <h2
          v-scroll-reveal="$sr_rightSetting"
          class="h2-super_title txt-bold">
            即將前往海外出差
            <img src="@/assets/img/icon/icon_exclamation.svg" alt="">
        </h2>
      </div>
    </section>

    <section id="go_abroad">
      <div class="abroad_block start">
        <div class="bg">
          <figure
            :style="`background-image: url(${require('@/assets/img/company/img_1.jpg')});`">
          </figure>
        </div>
        <div class="inner w1300">
          <div class="cardbox">
            <div class="cardtitle">
              <h5 class="txt-white txt-bold">出國前4日</h5>
            </div>
            <div class="card_3item txt-white">
              <h5>Wi-Ho!出國上網三大利器</h5>
              <div class="itembox">
                <div class="item">
                  <img class="i1" src="@/assets/img/company/icon_tool_1.png" alt="">
                  <h6 class="txt-bold">WiFi分享器</h6>
                </div>
                <div class="item">
                  <img class="i2" src="@/assets/img/company/icon_tool_2.png" alt="">
                  <h6 class="txt-bold">SIM卡</h6>
                </div>
                <div class="item">
                  <img class="i3" src="@/assets/img/company/icon_tool_3.png" alt="">
                  <h6 class="txt-bold">eSIM</h6>
                </div>
              </div>
            </div>
            <div class="card">
                <div class="text">
                    <h6 class="txt-bold">準備前往海外商務出差旅途</h6>
                    <h6>機票與住宿預訂完成後，最重要且不可或缺的就是”出國網路”。</h6>
                    <div class="lightpoint">
                        <h6 class="txt-blue">
                          <img src="@/assets/img/products/icon_v_b.svg" alt="">&nbsp;
                          網路預約
                        </h6>
                        <h6 class="txt-blue">
                          <img src="@/assets/img/products/icon_v_b.svg" alt="">&nbsp;
                          臨櫃租借
                        </h6>
                    </div>
                </div>
            </div>
            <div class="card">
              <div class="text">
                <h6 class="txt-bold">出國前1日</h6>
                <h6>企業戶專屬，急件處理。!</h6>
                <div class="lightpoint">
                  <h6 class="txt-blue">
                    <img src="@/assets/img/icon/icon_66.svg" alt="">&nbsp;
                    機場櫃台WiFi分享器或SIM卡
                  </h6>
                </div>
              </div>
              <div class="pic">
                <figure
                  :style="`background-image: url(${require('@/assets/img/company/img_a.jpg')});`">
                </figure>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="abroad_block">
        <div class="bg">
          <figure
            :style="`background-image: url(${require('@/assets/img/company/img_2.jpg')});`">
          </figure>
        </div>
        <div class="inner w1300">
          <div class="cardbox">
            <div class="cardtitle">
              <h5 class="txt-white txt-bold">出發前往目的地</h5>
            </div>
            <div class="card">
              <div class="text">
                <h6 class="txt-bold">抵達當地</h6>
                <h6>抵達當地後，啟動WiFi分享器或更換SIM卡，即可使用網路。過程簡單又快速！</h6>
              </div>
            </div>
            <div class="card short">
              <div class="text">
                <h6 class="txt-bold">便利的差旅就此展開</h6>
                <h6>在海外的交通與需查詢確認的大小事，通通交給Wi-Ho!的WiFi分享器與SIM卡吧！</h6>
              </div>
            </div>
            <div class="card_email">
              <div
                class="pic_bg"
                :style="`background-image: url(${require('@/assets/img/company/img_2.2.jpg')});`">
                  <div class="text txt-white">
                    <h5>立即收發email/訊息</h5>
                    <h6 class="txt-bold">即時回覆狀況。<br>天氣晴朗，開啟順利的一天吧！</h6>
                  </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="abroad_block">
        <div class="bg">
          <figure
            :style="`background-image: url(${require('@/assets/img/company/img_3.jpg')});`">
          </figure>
        </div>
        <div class="inner w1300">
          <div class="cardbox">
            <div class="card short">
              <div class="text">
                <h6 class="txt-bold">商務差旅，『休息充電』還是相當重要</h6>
                <h6>透過WiFi分享器或SIM卡的網路，搜尋當地人氣景點與美食，好好放鬆一下！</h6>
              </div>
            </div>
            <div class="card short">
              <div class="text">
                <h6 class="txt-bold">回到飯店，結束忙碌的一天</h6>
                <h6>工作業務告一段落，與家人親友通話。</h6>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="abroad_block end">
          <div class="bg">
            <figure
              :style="`background-image: url(${require('@/assets/img/company/img_4.jpg')});`">
            </figure>
            <figure class="white_op_bg"></figure>
          </div>
          <div class="inner w1300">
            <div class="cardbox">
              <div class="cardtitle">
                <h5 class="txt-white txt-bold">結束商務旅途</h5>
              </div>
              <div class="card">
                <div class="text">
                  <h6 class="txt-bold">返國抵達台灣</h6>
                  <h6>
                    <span class="txt-bold txt-blue">
                      <span class="txt-highbold">WiFi</span>分享器
                    </span> 於機場櫃台歸還<br>
                    <span class="txt-highbold txt-blue">SIM卡</span> 更換回原SIM卡即可<br>
                    <span class="txt-highbold txt-blue">eSIM</span> 刪除設定並重新連線網路
                  </h6>
                </div>
                <div class="pic" style="margin: 6px;">
                  <figure
                    class="c"
                    :style="`background-image: url(${require('@/assets/img/company/img_b.jpg')});`">
                  </figure>
                </div>
              </div>
              <div class="card_next">
                <div class="text txt-white">
                  <img class="logo" src="@/assets/img/logo.svg" alt="">
                  <h5>
                    與您下次旅程見&nbsp;
                    <img
                      src="@/assets/img/icon/icon_exclamation-w.svg"
                      alt=""
                      style="width: .65em;">
                  </h5>
                </div>
              </div>
            </div>
          </div>
      </div>
    </section>

    <section id="g_block">
      <div
        class="bg"
        style="background-image: url(@/assets/img/icon/deco_exclamation_big.svg);">
      </div>
      <div class="w1300">
        <h2
          v-scroll-reveal="$sr_rightSetting"
          class="h2-super_title txt-bold txt-center">
            有商務合作需求嗎？<br>
            立即洽詢企業方案
            <img src="@/assets/img/icon/icon_exclamation.svg" alt="">
        </h2>
        <div class="btnbox w1300 txt-center">
          <button
            type="button"
            class="btn btn-purple_purple_line"
            @click="companyLightboxStatusChange(true);">
              <img src="@/assets/img/icon/icon_next-gl.svg" alt="">
              <p>立即聯繫</p>
          </button>
        </div>
      </div>
    </section>

    <CompanyIconBlock/>

    <section
      id="b_block"
      :style="`background-image: url(${require('@/assets/img/company/img_5.jpg')});`">
        <div
          class="bg"
          :style="
            `background-image: url(${require('@/assets/img/icon/deco_exclamation_big.svg')});`
          ">
        </div>
        <div class="text w1300 txt-center">
          <img class="logo" src="@/assets/img/company/logo_wiho.png" alt="">
          <h2
            v-scroll-reveal="$sr_rightSetting"
            class="h2-super_title txt-white txt-bold txt-center">
              提供客戶最有效的<br>
              海外出差上網需求服務
              <img src="@/assets/img/icon/icon_exclamation-w.svg" alt="">
          </h2>
        </div>
    </section>
  </div>
</template>

<script>
// @ is an alias to /src
import { mapMutations, mapState } from 'vuex';
import Header from '@/components/Header.vue';
import CompanyKv from '@/components/company/CompanyKv.vue';
import CompanyIconBlock from '@/components/company/CompanyIconBlock.vue';
import PublicDiscountSection from '@/components/PublicDiscountSection.vue';
import requestApi from '@/lib/http/index';
import { getStorage } from '../utils/timerStorage';

import '@/assets/scss/company.scss';

export default {
  name: 'CompanyArea',
  components: {
    Header,
    CompanyKv,
    PublicDiscountSection,
    CompanyIconBlock,
  },
  data() {
    return {
      lastNews: [],
      show: false,
    };
  },
  computed: {
    ...mapState([
      'lightboxStatus',
    ]),
  },
  methods: {
    ...mapMutations([
      'coopLightboxStatusChange',
      'companyLightboxStatusChange',
    ]),
    async getLastNews() {
      this.lastNews = [];
      let code = '';
      if (getStorage('marketCode')) {
        code = localStorage.getItem('marketCode');
      }

      const result = await requestApi('companyArticles.getLastArticle', { code });

      result.data.forEach((element) => {
        const {
          id,
          type,
          // eslint-disable-next-line
          publish_day,
          title,
        } = element;
        const item = {
          route: {
            name: 'CompanyInfo',
            params: {
              id,
            },
          },
          type,
          publish_day,
          title,
        };
        this.lastNews.push(item);
      });
    },
    checkMarket() {
      if (getStorage('marketCode')) {
        this.show = true;
      }
    },
  },
  mounted() {
    this.getLastNews();
    this.checkMarket();
  },
};
</script>
