<template>
  <section id="reserve_top">
    <div class="inner">
      <div class="w1300">
        <p class="txt-blue txt-black txt-style-i">
          Time To Travel
          <img src="@/assets/img/icon/icon_exclamation.svg" alt="" style="width: .65em;">
        </p>
        <h2 class="h2-super_title txt-bold sr_right">
          搜尋 <span class="txt-black txt-style-i">Wi-Ho!</span> 商品
        </h2>

        <div class="search_area">
          <div class="tagset">
            <!-- 目前的種類加.active -->
            <router-link
              :to="{
                name:'Product',
                params:{ category: 1 },
                query: {
                  productCategory: 0, sort: this.$route.query.sort || 1,
                  code: null || $route.query.code
                }
              }"
              :class="{ active: category == 1 }"
              class="tag">
                <h6 class="txt-white">上網工具</h6>
            </router-link>
            <router-link
              :to="{
                name:'Product',
                params:{ category: 2 },
                query: { productCategory: 0,
                sort: this.$route.query.sort || 1, code: null || $route.query.code
              },
              }"
              :class="{ active: category == 2 }"
              class="tag">
                <h6 class="txt-white">旅遊周邊</h6>
            </router-link>
          </div>

          <!-- 上網工具的搜尋表單 -->
          <div
            v-if="category == 1"
            id="searchForm"
            class="search_box">
              <div class="search_item col30" style="max-height: 70px; overflow: hidden;">
                <div class="name_block">
                  <p class="item_name txt-white">目的地</p>
                </div>
                <WhSelect
                  v-model="toolSearch.targetCountry"
                  :options="countries"
                  :value="toolSearch.targetCountry"
                  placeholder="請輸入目的地"
                  limit="2"
                />
                <!-- <Select2
                  id="index_destination"
                  name="countries"
                  placeholder="請輸入目的地"
                  v-model="toolSearch.targetCountry"
                  :options="countries"
                  :settings="{
                    multiple:'multiple',
                    maximumSelectionLength: 2,
                  }"/> -->
              </div>
              <div id="page_start_time" class="search_item col30">
                <div class="name_block">
                  <p class="item_name txt-white">時間</p>
                  <input type="checkbox" name="unspecifiedTime" id="no_time_limit"
                  v-model="toolSearch.unspecifiedTime">
                  <label for="no_time_limit" class="txt-white p"><span></span>不指定日期</label>
                </div>
                <div
                class="dateTime"
                :class="{active: !toolSearch.unspecifiedTime}">
                  <date-picker
                    v-model="toolSearch.dateRange"
                    class="datePicker"
                    type="date"
                    value-type="YYYY/MM/DD"
                    range
                    :disabled="toolSearch.unspecifiedTime"
                    :disabledDate="date => (
                      date <= new Date().setDate(new Date().getDate() - 1)
                      || date > new Date().setDate(new Date().getDate() + 180)
                    )"
                    placeholder="出國日 － 回國日">
                  </date-picker>
                </div>
              </div>
              <div class="search_item col20">
                <div class="name_block">
                  <p class="item_name txt-white">上網工具</p>
                </div>
                <select v-model="toolSearch.productCategory">
                    <option value="0">
                        全部
                    </option>
                    <option
                      v-for="(category, i) in productCategoryInternet"
                      :key="`productCategory_${i}`" :value="category.id">
                        {{category.text}}
                    </option>
                </select>
              </div>
              <div class="search_item col20">
                <div class="name_block">
                  <p class="item_name txt-white">流量</p>
                </div>
                <select v-model="toolSearch.flow">
                  <option
                  v-for="(flow, i) in flowTypes" :key="`flow_${i}`" :value="flow.id">
                    {{flow.text}}
                  </option>
                </select>
              </div>
              <div class="search_item col20" style="min-width: 180px;">
                <div class="txt-right">
                  <button id="clean_allfliter" style="display:inline-block;" type="button">
                    <p class="item_name txt-white" @click="clear">清除</p>
                  </button>
                </div>
                <div
                  class="btn btn-orange_white_line"
                  style="width: 100%; display: block;"
                  @click="search">
                    <img src="@/assets/img/icon/icon_next-o.svg" alt="">
                    <p>立即搜尋</p>
                </div>
              </div>
          </div>

          <!-- 週邊產品的搜尋表單 -->
          <div
            v-if="category == 2"
            id="searchForm"
            class="search_box travel">
              <div class="search_item col20">
                <div class="name_block">
                  <p class="item_name txt-white">分類</p>
                </div>
                <select
                  name="category_id"
                  id="category_id"
                  v-model="travelSearch.productCategory">
                    <option value="0">
                        全部
                    </option>
                    <option
                      v-for="(category, i) in productCategory"
                      :key="`productCategory_${i}`" :value="category.text">
                        {{category.text}}
                    </option>
                </select>
              </div>
              <div class="search_item col20" style="min-width: 180px;">
                <div
                  class="btn btn-orange_white_line"
                  style="width: 100%; display: block;"
                  @click="categorySearch">
                    <img src="@/assets/img/icon/icon_next-o.svg" alt="">
                    <p>立即搜尋</p>
                </div>
              </div>
          </div>
        </div>
      </div>
    </div>

    <!-- 如果是旅遊周邊 reserve_top_box 要加上 travel 的 class  -->
    <swiper
      :options="swiperOption"
      class="reserve_top_box travel">
        <template v-if="banner && banner.length > 0">
          <swiper-slide
            v-for="(img, i) in banner"
            :key="`banne_${i}`"
          >
            <figure class="reserve_top_item">
              <div
                class="half"
                :style="`background-image: url(${img});`"
              >
              </div>
            </figure>
          </swiper-slide>
        </template>
        <template v-else>
          <swiper-slide
            v-for="(item, idx) in 10"
            :key="`swiper-slide-item-${item}-${idx}`"
          >
            <figure class="reserve_top_item">
              <div
                class="half"
                :style="{
          'background-image': 'url(' + require(`@/assets/img/products/product_bg_${idx+1}.jpg`) + ''
                }"
              >
              </div>
            </figure>
          </swiper-slide>
        </template>
    </swiper>

  </section>
</template>

<script>
import { mapMutations } from 'vuex';
import moment from 'moment';
import DatePicker from 'vue2-datepicker';
import 'vue2-datepicker/locale/zh-cn';
import WhSelect from '@/components/index/WhSelect.vue';
import requestApi from '@/lib/http/index';

export default {
  name: 'ProductFilter',
  props: ['category', 'filterData'],
  components: {
    DatePicker,
    WhSelect,
  },
  data() {
    return {
      banner: [],
      countries: [],
      flowTypes: [],
      productCategoryInternet: [],
      productCategory: [],
      toolSearch: {
        targetCountry: [], // 統一由 watch 以array形式寫入
        unspecifiedTime: !!+this.filterData.unspecifiedTime,
        dateRange: [this.filterData.abroad_start, this.filterData.abroad_end],
        productCategory: '0',
        flow: this.filterData.flowType || '',
      },
      travelSearch: {
        productCategory: '0',
      },
      swiperOption: {
        loop: true,
        slidesPerView: 1,
        spaceBetween: 0,
        allowTouchMove: true,
        effect: 'fade',
        autoplay: {
          delay: 5000,
          stopOnLastSlide: false,
          disableOnInteraction: true,
        },
      },
    };
  },
  methods: {
    ...mapMutations([
      'setToolSearch',
    ]),
    search() {
      const {
        dateRange, flow, productCategory, targetCountry, unspecifiedTime,
      } = this.toolSearch;
      const searchForm = {
        unspecifiedTime: unspecifiedTime === false ? 0 : 1,
      };
      if (flow) {
        searchForm.flowType = flow;
      }
      if (productCategory) {
        searchForm.productCategory = productCategory;
      } else {
        searchForm.productCategory = this.$route.query.productCategory;
      }
      if (targetCountry && targetCountry.length > 0) {
        searchForm.countries = [];
        targetCountry.forEach((country) => {
          searchForm.countries.push(country);
        });
      }
      if (dateRange && dateRange.length > 0) {
        if (dateRange[0] && dateRange[1]) {
          const [abroadStart, abroadEnd] = dateRange;
          searchForm.abroad_start = moment(abroadStart).format('YYYY/MM/DD');
          searchForm.abroad_end = moment(abroadEnd).format('YYYY/MM/DD');
        }
      }
      const obj = {
        name: 'Product',
        params: { category: 1 },
        query: { ...searchForm, sort: this.$route.query.sort || 1, code: '' || this.$route.query.code },
      };
      this.$router.push(obj);
    },
    categorySearch() {
      const { productCategory } = this.travelSearch;
      const searchForm = {};
      if (productCategory) {
        searchForm.productCategory = productCategory;
      }
      const obj = {
        name: 'Product',
        params: { category: 2 },
        query: { ...searchForm, sort: this.$route.query.sort || 1, code: '' || this.$route.query.code },
      };
      this.$router.push(obj);
    },
    clear() {
      Object.keys(this.toolSearch).forEach((key) => {
        if (key === 'unspecifiedTime') {
          this.toolSearch[key] = false;
        } else {
          this.toolSearch[key] = '';
        }
      });
    },
    async getFilter() {
      const Countries = new Promise((resolve, reject) => {
        const getAreaWithCountries = requestApi('req.getAreaWithCountries');
        getAreaWithCountries.then((res) => {
          if (res.status) {
            const arr = [];
            res.data.forEach((area) => {
              if (area.name) {
                arr.push({ id: area.id, text: area.name, disabled: true });
              }
              if (area.countries.length > 0) {
                area.countries.forEach((country) => {
                  if (country.name && country.id) {
                    arr.push({ id: country.id, text: country.name, disabled: false });
                  }
                });
              }
            });
            resolve(arr);
          } else {
            reject(res.message);
          }
        });
      });
      const FlowTypes = new Promise((resolve, reject) => {
        const getflowTypes = requestApi('home.getflowTypes');
        getflowTypes.then((res) => {
          if (res.status) {
            const arr = [
              { id: '', text: '全部' },
            ];
            res.data.forEach((element) => {
              arr.push({
                id: element.id, text: element.name,
              });
            });
            resolve(arr);
          } else {
            reject(res.message);
          }
        });
      });
      const ProductCategory = new Promise((resolve, reject) => {
        const getProductCategories = requestApi('req.getProductCategories', { parent: '旅遊周邊' });
        getProductCategories.then((res) => {
          if (res.status) {
            const arr = [
              // { id: '', text: '全部' },
            ];
            res.data.forEach((element) => {
              if (element.name !== 'WIFI分享器' && element.name !== 'SIM卡' && element.name !== 'eSIM') {
                arr.push({
                  id: element.id, text: element.name,
                });
              }
            });
            resolve(arr);
          } else {
            reject(res.message);
          }
        });
      });
      const ProductCategoryInternet = new Promise((resolve, reject) => {
        const getProductCategories = requestApi('req.getProductCategories', { parent: '上網工具' });
        getProductCategories.then((res) => {
          if (res.status) {
            const arr = [
              // { id: '', text: '全部' },
            ];
            res.data.forEach((element) => {
              if (element.name === 'WIFI分享器') {
                arr.push({
                  id: 1, text: element.name,
                });
              } else if (element.name === 'SIM卡') {
                arr.push({
                  id: 2, text: element.name,
                });
              } else if (element.name === 'eSIM') {
                arr.push({
                  id: 3, text: element.name,
                });
              } else {
                arr.push({
                  id: element.name, text: element.name,
                });
              }
            });
            resolve(arr);
          } else {
            reject(res.message);
          }
        });
      });
      Promise.all([
        Countries, FlowTypes, ProductCategory, ProductCategoryInternet,
      ]).then((values) => {
        const [countries, flowTypes, productCategory, productCategoryInternet] = values;
        this.countries = countries;
        this.flowTypes = flowTypes;
        this.productCategory = productCategory;
        this.productCategoryInternet = productCategoryInternet;
        this.checkCategory();
      });
    },
    getCategoryId(name) {
      let id = '0';
      this.productCategoryInternet.forEach((item) => {
        if (item.text === name) {
          id = item.id;
        }
      });
      return id;
    },
    getOrderCategoryId(name) {
      let id = '0';
      this.productCategory.forEach((item) => {
        if (item.text === name) {
          id = item.text;
        }
      });
      this.productCategoryInternet.forEach((item) => {
        if (item.text === name) {
          id = item.id;
        }
      });
      return id;
    },
    checkCategory() {
      if (parseInt(this.$route.query.productCategory, 10) === 1) {
        this.toolSearch.productCategory = this.getCategoryId('WIFI分享器');
      } else if (parseInt(this.$route.query.productCategory, 10) === 2) {
        this.toolSearch.productCategory = this.getCategoryId('SIM卡');
      } else if (parseInt(this.$route.query.productCategory, 10) === 3) {
        this.toolSearch.productCategory = this.getCategoryId('eSIM');
      } else if (parseInt(this.$route.params.category, 10) === 1) {
        this.toolSearch.productCategory = this.getOrderCategoryId(
          this.$route.query.productCategory,
        );
      } else if (parseInt(this.$route.params.category, 10) === 2) {
        this.travelSearch.productCategory = this.getOrderCategoryId(
          this.$route.query.productCategory,
        );
      }
    },
    async getBanner() {
      const category = '上網工具';
      const getResult = await requestApi('product.searchResult', { category });
      if (getResult.status) {
        const arr = [];
        getResult.data.forEach((item) => {
          if (item.bg_img) {
            arr.push(item.bg_img);
          }
        });
        this.banner = arr;
      }
    },
  },
  watch: {
    filterData: {
      deep: true,
      immediate: true,
      handler() {
        if (typeof (this.filterData.countries) === 'string') {
          const arr = [];
          arr.push(this.filterData.countries);
          this.toolSearch.targetCountry = arr;
        } else {
          this.toolSearch.targetCountry = this.filterData.countries;
        }
      },
    },
    'toolSearch.unspecifiedTime': function () {
      if (this.toolSearch.unspecifiedTime === true) {
        this.toolSearch.dateRange = [];
      }
    },
    toolSearch: {
      handler() {
        this.setToolSearch(this.toolSearch);
      },
      deep: true,
      immediate: true,
    },
    $route() {
      this.checkCategory();
    },
  },
  mounted() {
    this.getFilter();
    this.getBanner();
  },
};
</script>
<style lang="scss" scope>
  #reserve_top .inner .search_box #page_start_time input[type=text],
  #reserve_top .inner .search_box #page_start_time input[type=text] {
    opacity: 1 !important;
  }
  #page_start_time > .dateTime {
      opacity: .5;
      pointer-events: none;
      &.active{
          opacity: 1;
          pointer-events: all;
      }
  }
</style>
