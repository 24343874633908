<template>
  <section id="go_abroad">
    <swiper
      v-if="eventData && eventData.length"
      :options="swiperOption"
      class="go_abroad_box box1 swiper-container">
      <swiper-slide
        v-for="(item, key) in eventData"
        :key="key">
        <router-link :to="`/EventInfo/${item.id}`" class="go_abroad_item">
          <figure :style="{backgroundImage: `url(${item.pic})`}"></figure>
          <div class="cover">
              <!-- <div class="text txt-center txt-white">
                  <h5>【{{item.category}}】</h5>
                  <h5>{{item.title}}</h5>
              </div> -->
              <p class="see_more txt-white">
                <span class="txt-style-i">
                  SEE MORE
                </span> <img src="@/assets/img/icon/icon_wave_w.svg" alt="">
              </p>
          </div>
        </router-link>
      </swiper-slide>
      <div class="swiper-pagination" slot="pagination"></div>
    </swiper>

  </section>
</template>

<script>
// import img1 from '@/assets/img/temp/temp001.jpeg';

export default {
  name: 'IndexGoAbroad',
  props: ['eventData'],
  data() {
    return {
      // eventData: [
      //   {
      //     pic: img1,
      //     link: 'https://www.google.com.tw/',
      //   },
      // ],
      swiperOption: {
        loop: true,
        slidesPerView: 3,
        spaceBetween: 0,
        allowTouchMove: true,
        pagination: {
          el: '#go_abroad .swiper-pagination',
          clickable: true,
        },
        autoplay: {
          delay: 5000,
          stopOnLastSlide: false,
          disableOnInteraction: true,
        },
        breakpoints: {
          1023: {
            slidesPerView: 2.5,
          },
          880: {
            slidesPerView: 2,
          },
          600: {
            slidesPerView: 1.5,
          },
          425: {
            slidesPerView: 1,
          },
        },
      },
    };
  },
};
</script>
