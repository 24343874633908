<template>
  <section id="full_use" :style="{backgroundImage: `url(${nowChooseItemData.bg})`}">
      <div class="text">
          <h2
            v-scroll-reveal="$sr_rightSetting"
            id="full_use-show_title"
            class="h2-super_title txt-white txt-bold">
            {{nowChooseItemData.title}}
            <img src="@/assets/img/icon/icon_exclamation-w.svg" alt="">
          </h2>

          <ul
            v-scroll-reveal="$sr_rightSetting"
            id="full_use-show_list">
            <li
              v-for="(item, key) in nowChooseItemData.infoList"
              :key="key"
              class="h6 txt-white">
                <img src="@/assets/img/icon/icon_yes-w.svg" alt="">
                &nbsp;{{item}}
            </li>
          </ul>
      </div>

      <div class="itembox">
        <a
          v-for="(item, key) in itemData"
          :key="key"
          :class="{active: key == nowChooseItemNum}"
          class="item"
          @click="changeChooseItem(key)">
            <figure>
              <div v-html="item.svgIcon"></div>
              <h5 class="txt-bold">{{item.title}}</h5>
            </figure>
        </a>
      </div>
  </section>
</template>

<script>
import bg01 from '@/assets/img/index/img_6.1.jpg';
import bg02 from '@/assets/img/index/img_6.2.jpg';
import bg03 from '@/assets/img/index/img_6.3.jpg';
import bg04 from '@/assets/img/index/img_6.4.jpg';
import bg05 from '@/assets/img/index/img_6.5.jpg';
import bg06 from '@/assets/img/index/img_6.6.jpg';

export default {
  name: 'IndexFullUse',
  data() {
    return {
      itemData: [
        {
          title: '真正吃到飽',
          infoList: ['不降速!「真正的」吃到飽', '多樣化的商品流量方案!', '創社46年的出國上網老品牌'],
          bg: bg01,
          svgIcon: `
            <svg xmlns="http://www.w3.org/2000/svg" width="68.915" height="65" viewBox="0 -10 68.915 65">
              <g id="Group_4542" data-name="Group 4542" transform="translate(1.117 -7.75)">
                <path id="Path_681" data-name="Path 681" class="con_stroke" d="M-108.179-226.662c5.679-11.295,18.482-19.1,33.341-19.1s27.662,7.809,33.341,19.1" transform="translate(108.179 254.766)" fill="none" stroke="#019db7" stroke-miterlimit="10" stroke-width="2.5"/>
                <path id="Path_682" data-name="Path 682" class="con_stroke" d="M-105.289-231.145c3.266-6.5,10.639-11,19.192-11s15.921,4.494,19.192,11" transform="translate(119.438 268.889)" fill="none" stroke="#019db7" stroke-miterlimit="10" stroke-width="2.5"/>
                <path id="Path_683" data-name="Path 683" class="con_fill" d="M-94.063-237.27a5.141,5.141,0,0,1,0,7.272,5.14,5.14,0,0,1-7.268,0,5.144,5.144,0,0,1,0-7.272A5.151,5.151,0,0,1-94.063-237.27Z" transform="translate(131.039 282.069)" fill="#019db7"/>
              </g>
            </svg>
          `,
        },
        {
          title: '多元取件',
          infoList: ['機場臨櫃取機取卡', '宅配到府', '便利商店取件'],
          bg: bg02,
          svgIcon: `
            <svg id="delivery-truck" xmlns="http://www.w3.org/2000/svg" width="79.133" height="65" viewBox="0 -10 79.133 65">
              <g id="Group_4844" data-name="Group 4844" transform="translate(0 0)">
                <g id="Group_4843" data-name="Group 4843">
                  <path id="Path_2733" data-name="Path 2733" class="con_fill" d="M1.236,132.277a1.237,1.237,0,0,1,1.236,1.236v8.664H4.44a7.814,7.814,0,0,1,15.53,0H45.749V106.32H2.473v3.889a1.236,1.236,0,1,1-2.473,0v-5.125a1.237,1.237,0,0,1,1.236-1.236H46.985a1.237,1.237,0,0,1,1.236,1.236v1.236h14.16A5.631,5.631,0,0,1,68,111.943v1.8a1.237,1.237,0,0,1-1.236,1.236H65.33l.943,11.332a5.619,5.619,0,0,1,4.2,5.419v10.45a1.236,1.236,0,0,1-1.236,1.236h-2.5a8.029,8.029,0,0,1-16.012,0c-.011,0-.02.006-.031.006H48.221a1.236,1.236,0,0,1-1.236,1.236H19.857a7.817,7.817,0,0,1-15.3,0H1.236A1.236,1.236,0,0,1,0,143.419v-9.906A1.236,1.236,0,0,1,1.236,132.277Zm57.495,16.082a5.564,5.564,0,1,0-5.564-5.564A5.564,5.564,0,0,0,58.731,148.359Zm-10.51-7.419h2.473a1.136,1.136,0,0,1,.214.043,8.027,8.027,0,0,1,15.635-.043H68v-9.214a3.154,3.154,0,0,0-3.15-3.15H48.221Zm0-14.837H63.776l-.927-11.128H48.221Zm0-13.6h17.31v-.559a3.154,3.154,0,0,0-3.15-3.15H48.221ZM6.869,143.364A5.334,5.334,0,0,0,12.2,148.4h0a5.343,5.343,0,0,0,5.252-4.43,1.113,1.113,0,0,1,.063-1.2,5.334,5.334,0,1,0-10.651.6Z" transform="translate(8.655 -103.847)" fill="#019db7"/>
                  <path id="Path_2734" data-name="Path 2734" class="con_fill" d="M321.236,167.847h17.31a1.236,1.236,0,1,1,0,2.473h-17.31a1.236,1.236,0,1,1,0-2.473Z" transform="translate(-310.108 -157.955)" fill="#019db7"/>
                  <path id="Path_2735" data-name="Path 2735" class="con_fill" d="M273.236,255.847h27.2a1.236,1.236,0,0,1,0,2.473h-27.2a1.236,1.236,0,0,1,0-2.473Z" transform="translate(-264.581 -232.355)" fill="#019db7"/>
                  <path id="Path_2736" data-name="Path 2736" class="con_fill" d="M345.236,207.847h13.6a1.236,1.236,0,1,1,0,2.473h-13.6a1.236,1.236,0,1,1,0-2.473Z" transform="translate(-334.108 -191.773)" fill="#019db7"/>
                  <path id="Path_2737" data-name="Path 2737" class="con_fill" d="M467.709,170.32h-2.473a1.236,1.236,0,1,1,0-2.473h2.473a1.236,1.236,0,0,1,0,2.473Z" transform="translate(-461.527 -157.955)" fill="#019db7"/>
                  <path id="Path_2738" data-name="Path 2738" class="con_fill" d="M473.236,255.847h2.473a1.236,1.236,0,0,1,0,2.473h-2.473a1.236,1.236,0,0,1,0-2.473Z" transform="translate(-470.764 -232.355)" fill="#019db7"/>
                  <path id="Path_2739" data-name="Path 2739" class="con_fill" d="M465.236,207.847h4.946a1.236,1.236,0,1,1,0,2.473h-4.946a1.236,1.236,0,1,1,0-2.473Z" transform="translate(-464 -191.773)" fill="#019db7"/>
                </g>
              </g>
            </svg>
          `,
        },
        {
          title: '機場櫃台',
          infoList: ['3大機場櫃點、新竹與台中門市', '出國當日可臨櫃租借,專業服務人員親切說明、協助設定', '松山機場24小時SIM卡自動販賣機'],
          bg: bg03,
          svgIcon: `
            <svg xmlns="http://www.w3.org/2000/svg" width="82.757" height="65" viewBox="0 -10 82.757 65">
              <g id="Group_4842" data-name="Group 4842" transform="translate(-115.888 -2886.289)">
                <path id="Path_2731" data-name="Path 2731" class="con_stroke" d="M2406.343,2868.059l-6.206,2.125a47.77,47.77,0,0,0,4.166,11.075c2.9,5.327,6.86,9.137,13.63,6.641s19.439-6.641,19.439-6.641l-3.635,15.649,11.39-4.154,7.8-17.691,24.321-8.906s3.929-1.97,2.926-5.3-10.975-6.371-17.754-4.434-48.737,17.369-48.737,17.369Z" transform="translate(-2283 37.392)" fill="none" stroke="#019db7" stroke-linecap="round" stroke-linejoin="round" stroke-width="2.5"/>
                <path id="Path_2732" data-name="Path 2732" class="con_stroke" d="M2537.595,2837.28l-14.772-11.617,11.38-4.124,18.654,10.148" transform="translate(-2385.398 66)" fill="none" stroke="#019db7" stroke-linecap="round" stroke-linejoin="round" stroke-width="2.5"/>
              </g>
            </svg>
          `,
        },
        {
          title: '急件預約服務',
          infoList: ['可對應緊急出國上網需求', '出國當日也可臨櫃租借'],
          bg: bg04,
          svgIcon: `
            <svg xmlns="http://www.w3.org/2000/svg" width="65.574" height="65" viewBox="0 -5 65.574 67">
              <g id="Group_4543" data-name="Group 4543" transform="translate(-254.79 146.018)">
                <g id="Group_1891" data-name="Group 1891" transform="translate(264.929 -135.887)">
                  <path id="Path_689" data-name="Path 689" class="con_stroke" d="M304.971-97.408H259.677v-25.937a17.79,17.79,0,0,1,17.79-17.79h9.716a17.789,17.789,0,0,1,17.788,17.79Z" transform="translate(-259.677 141.135)" fill="none" stroke="#019db7" stroke-miterlimit="10" stroke-width="2.5"/>
                </g>
                <rect id="Rectangle_303" data-name="Rectangle 303" class="con_stroke" width="54.264" height="6.135" transform="translate(260.445 -92.153)" fill="none" stroke="#019db7" stroke-miterlimit="10" stroke-width="2.5"/>
                <g id="Group_1893" data-name="Group 1893" transform="translate(283.831 -127.273)">
                  <g id="Group_1892" data-name="Group 1892">
                    <path id="Path_690" data-name="Path 690" class="con_stroke" d="M268.788-136.983h7.49v9.811l-.075.975c-.263,2.191-.942,6.577-.942,6.577h-5.454s-.681-4.386-.944-6.577l-.075-.975v-9.811h7.49m-6.809,22.056h6.016v5.5h-6.016v-5.5Z" transform="translate(-268.788 136.983)" fill="none" stroke="#019db7" stroke-miterlimit="10" stroke-width="2.5"/>
                  </g>
                </g>
                <line id="Line_149" data-name="Line 149" class="con_stroke" y2="5.617" transform="translate(287.536 -146.018)" fill="#fff" stroke="#019db7" stroke-miterlimit="10" stroke-width="2.5"/>
                <line id="Line_150" data-name="Line 150" class="con_stroke" x2="5.617" transform="translate(254.79 -113.327)" fill="#fff" stroke="#019db7" stroke-miterlimit="10" stroke-width="2.5"/>
                <line id="Line_151" data-name="Line 151" class="con_stroke" x2="3.972" y2="3.972" transform="translate(261.823 -136.494)" fill="#fff" stroke="#019db7" stroke-miterlimit="10" stroke-width="2.5"/>
                <line id="Line_152" data-name="Line 152" class="con_stroke" x1="3.972" y2="3.972" transform="translate(309.358 -136.494)" fill="#fff" stroke="#019db7" stroke-miterlimit="10" stroke-width="2.5"/>
                <line id="Line_153" data-name="Line 153" class="con_stroke" x2="5.617" transform="translate(314.747 -113.327)" fill="#fff" stroke="#019db7" stroke-miterlimit="10" stroke-width="2.5"/>
              </g>
            </svg>
          `,
        },
        {
          title: '中英日客服支援',
          infoList: ['365天專業客服，提供親切完善的服務', '電話、LINE等多管道客服聯繫方式'],
          bg: bg05,
          svgIcon: `
            <svg xmlns="http://www.w3.org/2000/svg" width="67.5" height="65" viewBox="0 0 67.5 65">
              <g id="Group_4545" data-name="Group 4545" transform="translate(1.25 1.25)">
                <path id="Path_678" data-name="Path 678" class="con_stroke" d="M268.762-18.952a23.073,23.073,0,0,0-23.055-22.03,23.081,23.081,0,0,0-23.053,22.03h-4.738a27.81,27.81,0,0,1,27.791-26.766,27.819,27.819,0,0,1,27.8,26.766Z" transform="translate(-213.211 45.718)" fill="none" stroke="#019db7" stroke-miterlimit="10" stroke-width="2.5"/>
                <g id="Group_1816" data-name="Group 1816" transform="translate(51.407 26.707)">
                  <rect id="Rectangle_275" data-name="Rectangle 275" class="con_stroke" width="13.593" height="23.308" fill="none" stroke="#019db7" stroke-miterlimit="10" stroke-width="2.5"/>
                </g>
                <g id="Group_1817" data-name="Group 1817" transform="translate(0 26.707)">
                  <rect id="Rectangle_276" data-name="Rectangle 276" class="con_stroke" width="13.593" height="23.308" fill="none" stroke="#019db7" stroke-miterlimit="10" stroke-width="2.5"/>
                </g>
                <path id="Path_679" data-name="Path 679" class="con_stroke" d="M256.734-17.658v3.47a4.855,4.855,0,0,1-4.853,4.855h-15.2" transform="translate(-198.531 67.672)" fill="none" stroke="#019db7" stroke-miterlimit="10" stroke-width="2.5"/>
                <g id="Group_1819" data-name="Group 1819" transform="translate(3.898 36.263)">
                  <line id="Line_129" data-name="Line 129" class="con_stroke" x2="5.798" fill="none" stroke="#019db7" stroke-miterlimit="10" stroke-width="2.5"/>
                  <g id="Group_1818" data-name="Group 1818" transform="translate(0 4.195)">
                    <line id="Line_130" data-name="Line 130" class="con_stroke" x2="5.798" fill="none" stroke="#019db7" stroke-miterlimit="10" stroke-width="2.5"/>
                  </g>
                </g>
                <g id="Group_1821" data-name="Group 1821" transform="translate(55.305 36.263)">
                  <line id="Line_131" data-name="Line 131" class="con_stroke" x2="5.798" fill="none" stroke="#019db7" stroke-miterlimit="10" stroke-width="2.5"/>
                  <g id="Group_1820" data-name="Group 1820" transform="translate(0 4.195)">
                    <line id="Line_132" data-name="Line 132" class="con_stroke" x2="5.798" fill="none" stroke="#019db7" stroke-miterlimit="10" stroke-width="2.5"/>
                  </g>
                </g>
                <g id="Group_1822" data-name="Group 1822" transform="translate(26.845 54.667)">
                  <rect id="Rectangle_277" data-name="Rectangle 277" class="con_stroke" width="11.303" height="7.286" rx="2.044" transform="translate(0 0)" fill="none" stroke="#019db7" stroke-miterlimit="10" stroke-width="2.5"/>
                </g>
              </g>
            </svg>
          `,
        },
        {
          title: '客戶高評價',
          infoList: ['客戶使用滿意度問卷調查，GREAT評價肯定', '提供安心、便利、划算的全方位服務'],
          bg: bg06,
          svgIcon: `
            <svg xmlns="http://www.w3.org/2000/svg" width="63.242" height="65" viewBox="0 0 63.242 65">
              <g id="Group_4546" data-name="Group 4546" transform="translate(1.25 1.25)">
                <g id="Group_1807" data-name="Group 1807" transform="translate(18.135 0)">
                  <path id="Path_667" data-name="Path 667" class="con_stroke" d="M106.489,1.324H127.4a4.45,4.45,0,0,1,0,8.9h-4.539" transform="translate(-89.256 18.926)" fill="none" stroke="#019db7" stroke-miterlimit="10" stroke-width="2.5"/>
                  <path id="Path_668" data-name="Path 668" class="con_stroke" d="M117.461,4.82a4.442,4.442,0,0,1,0,8.884h-4.539" transform="translate(-79.314 24.329)" fill="none" stroke="#019db7" stroke-miterlimit="10" stroke-width="2.5"/>
                  <path id="Path_669" data-name="Path 669" class="con_stroke" d="M117.461,8.31a4.439,4.439,0,0,1,3.149,7.588,4.367,4.367,0,0,1-3.149,1.311h-4.539" transform="translate(-79.314 29.723)" fill="none" stroke="#019db7" stroke-miterlimit="10" stroke-width="2.5"/>
                  <path id="Path_670" data-name="Path 670" class="con_stroke" d="M119.085,13.619c2.5-3.719,4.518-7.377,5.34-11.758a10.821,10.821,0,0,0-.176-5.694,4.549,4.549,0,0,0-4.167-2.8,4.474,4.474,0,0,0-3.131,1.311C116-4.381,113.294.667,112.3,2.35a27.331,27.331,0,0,1-9.115,9.724,16.273,16.273,0,0,1-3.462,1.545V44.058l8,3.011a32.391,32.391,0,0,0,11.562,2.113H133.7a4.441,4.441,0,0,0,0-8.881" transform="translate(-99.719 6.631)" fill="none" stroke="#019db7" stroke-miterlimit="10" stroke-width="2.5"/>
                </g>
                <g id="Group_1808" data-name="Group 1808" transform="translate(0 15.727)">
                  <circle id="Ellipse_89" data-name="Ellipse 89" class="con_stroke" cx="3.222" cy="3.222" r="3.222" transform="translate(5.845 26.977)" fill="none" stroke="#019db7" stroke-miterlimit="10" stroke-width="2.5"/>
                  <path id="Rectangle_270" data-name="Rectangle 270" class="con_stroke" d="M0,0H14.922a3.212,3.212,0,0,1,3.212,3.212V35.821a3.212,3.212,0,0,1-3.212,3.212H0a0,0,0,0,1,0,0V0A0,0,0,0,1,0,0Z" transform="translate(0 0)" fill="none" stroke="#019db7" stroke-miterlimit="10" stroke-width="2.5"/>
                </g>
              </g>
            </svg>
          `,
        },
      ],
      nowChooseItemNum: 0,
      nowChooseItemData: {},
    };
  },
  methods: {
    changeChooseItem(num) {
      this.nowChooseItemNum = num;
      this.nowChooseItemData = this.itemData[num];
    },
  },
  mounted() {
    this.changeChooseItem(0);
  },
};
</script>
