<!-- eslint-disable max-len -->
<template>
  <!-- 產品, 促銷中的產品加上onsale的class -->
  <div class="item">
    <!-- 產品上半部(固定) -->
    <div class="item_show">
      <!-- 產品背景與產品圖是上網工具才有 -->
      <!-- 產品背景 (上網工具) -->
      <figure
        v-if="$route.params.category == 1"
        class="item_pic"
        :style="`background-image: url(${item.bg_img}); background-color: #fff;`">
      </figure>
      <!-- 產品圖 (上網工具) -->
      <router-link
      v-if="$route.params.category == 1"
      :to="{ name: 'ProductInfo', params: { id: item.id } }"  class="product_pic">
        <figure
        v-if="item.category === 'SIM卡'"
        :style="`background-image: url(${require('@/assets/img/products/product_SIM.svg')});`" />
        <figure
        v-else-if="item.category === 'eSIM'"
        :style="`background-image: url(${require('@/assets/img/products/product_eSIM.svg')});`" />
        <figure
        v-else
        :style="`background-image: url(${item.cover});`"></figure>
      </router-link>

      <!-- 產品輪播是旅遊周邊才有 -->
      <!-- 產品輪播 (旅遊周邊) -->
      <div
        v-else-if="$route.params.category == 2 && item.cover"
        class="travel_pic">
        <product-item-swiper
          :id="item.id"
          :cover="item.cover"
          :images="item.images"
        />
      </div>

      <!-- 產品內容 -->
      <router-link
        :to="{
          name: 'ProductInfo',
          params: { id: item.id },
          query: { code: marketCode },
        }"
        class="item_info">
          <div class="info_bg"></div>
          <div class="info_in">
            <!-- 產品名稱 -->
            <h4 class="txt-bold">{{item.product_name}}</h4>
            <!-- tag -->
            <div
            v-for="(tag, i) in JSON.parse(item.special_tags)" :key="`tag_${i}`"
            class="tag-flow txt-white h6">{{tag}}</div>
            <br>
            <!-- 特色 -->
            <div class="note" v-for="(item, i) in JSON.parse(item.features)" :key="`feature_${i}`">
              <p>
                <img src="@/assets/img/products/icon_v_o.svg" alt="">&nbsp;
                {{item}}
              </p>
            </div>
            <!-- 優惠 -->
            <div class="discount">
                <template
                  v-for="(item, i) in item.promotion_tags">
                    <div
                      :key="`promotion_${i}`"
                      class="tag-discount txt-white p">
                        {{item}}
                    </div>
                    <br :key="`promotion_br_${i}`">
                </template>
                <!-- <div class="tag-discount txt-white p">
                    輸入 happy100 可享單筆現折$100
                </div><br> -->
            </div>
          </div>
      </router-link>

      <!-- 調整數量/訂購 -->
      <div class="item_control">
        <form class="control_in">
          <!-- eslint-disable max-len -->
          <!-- 加入收藏, 已加入收藏的愛心加上active的class -->
          <svg
            v-if="user.id"
            class="heart" xmlns="http://www.w3.org/2000/svg"
            :class="{active: ifFav}"
            @click="updateFav();"
            width="27.222" height="25" viewBox="0 0 27.222 25">
            <g fill="none">
              <path class="heart_in" d="M13.611,25C6.293,20.008,2.454,15.467.88,11.667-3.337,1.481,8.708-3.389,13.611,2.623c4.9-6.013,16.948-1.142,12.731,9.043C24.768,15.467,20.93,20.008,13.611,25Z" stroke="none"/>
              <path d="M 13.61110687255859 23.17511749267578 C 19.47274398803711 19.04762649536133 23.38648223876953 14.88289451599121 24.95628547668457 11.09280490875244 C 26.33060646057129 7.773505210876465 25.60686683654785 5.562105178833008 24.75771713256836 4.291464805603027 C 23.60703659057617 2.569634914398193 21.51632690429688 1.500005006790161 19.30147552490234 1.500005006790161 C 17.47072601318359 1.500005006790161 15.86269664764404 2.235534906387329 14.77359676361084 3.571105003356934 L 13.61110687255859 4.996665000915527 L 12.44861602783203 3.571105003356934 C 11.35952663421631 2.235534906387329 9.751496315002441 1.500005006790161 7.920746326446533 1.500005006790161 C 5.706146240234375 1.500005006790161 3.615546464920044 2.569705009460449 2.464776515960693 4.291675090789795 C 1.615576386451721 5.562395095825195 0.8917464017868042 7.773825168609619 2.26586651802063 11.09263515472412 C 3.835737705230713 14.88288688659668 7.749468803405762 19.04762649536133 13.61110687255859 23.17511749267578 M 13.61110687255859 25.0000057220459 C 6.292506217956543 20.00831413269043 2.45418643951416 15.46722507476807 0.8800364136695862 11.66662502288818 C -2.075560569763184 4.528192520141602 2.957449436187744 4.473876913380082e-07 7.920747756958008 4.473876913380082e-07 C 10.03910732269287 4.473876913380082e-07 12.14447402954102 0.8246000409126282 13.61110687255859 2.62314510345459 C 15.07744312286377 0.8249670267105103 17.18354797363281 4.473876913380082e-07 19.30147361755371 4.473876913380082e-07 C 24.26519775390625 4.473876913380082e-07 29.29804801940918 4.527570724487305 26.34218597412109 11.66662502288818 C 24.76803588867188 15.46722507476807 20.92971611022949 20.00831413269043 13.61110687255859 25.0000057220459 Z" stroke="none" fill="#fff"/>
            </g>
          </svg>
          <div class="price txt-white">
            <!-- 售價, 原價, 單位 -->
            <div>
              <h6 class='txt-bold'>售價</h6>
              <h6 class="txt-regular txt-line-through" v-if="item.price">原價 {{item.price}}</h6>
            </div>
            <p class="big_set">
              <span class="h1 txt-style-i"><sup style="font-size:20px;">$</sup>{{item.sell_price}}</span>
              <span class="h6 txt-regular">/{{item.unit}}</span>
            </p>

            <!-- 如果沒有特價 -->
            <!-- <div>
                <h6 class='txt-bold'>售價</h6>
            </div>
            <p class="big_set">
                <span class="h1 txt-style-i"><sup style="font-size:20px;">$</sup>10000</span>
                <span class="h6 txt-regular">/台</span>
            </p> -->
          </div>

          <div class="select_box" v-if="parseInt(category, 10) === 1 && $route.query.productCategory == 3">
            <template v-if="brandOption.length > 0">
              <p class="txt-white">機型</p>
              <select
                style="margin-right: 10px"
                @input="selectBrand($event)"
                v-model="brand" class="small">
                  <template v-for="(brand, i) in brandOption">
                    <option v-show="brand.model.length > 0" :key="`brand_${i}`" :value="brand.id">{{brand.name}}</option>
                  </template>
              </select>
              <select v-model="model" class="small" v-if="brandOption[this.brandIndex].model.length > 0">
                  <option v-for="(selectBrand, i) in brandOption[this.brandIndex].model" :key="`color_${i}`" :value="selectBrand.id">{{selectBrand.name}}</option>
              </select>
            </template>
          </div>

          <div class="select_box" v-if="parseInt(category, 10) === 2 && item.colors.length > 0">
              <p class="txt-white">顏色</p>
              <select v-model="color" class="small">
                  <option v-for="(color, i) in item.colors" :key="`color_${i}`" :value="color.id">{{color.color_name}}</option>
              </select>
          </div>

          <div class="btnset txt-white">
            <div class="number" data-max="12">
              <div class="minus" data-id="" @click="count !== 1 ? count-- : 1">-</div>
              <input class="count" type="text" value="1" readonly v-model="count">
              <div class="plus" data-id="" data-member="" @click="addCount()">+</div>
            </div>
            <div class="btn_block">
              <!-- 預約 -->
              <!-- <a
                class="btn btn-yellow p"
                href="https://docs.google.com/forms/d/e/1FAIpQLSf9hObXXivKkPlkGxOZ6oHdAzBeYn1CxknzP7pbL9KurNBBDA/viewform"
                target="_blank">
                  <span>立即預約</span>
                  <img src="/img/icon/icon_exclamation.svg" alt="">
              </a> -->

              <!-- 購買 -->
              <div
                @click="addCart(true)"
                class="btn btn-yellow p">
                  <span>立即購買</span>&nbsp;
                  <img src="@/assets/img/icon/icon_exclamation.svg" alt="">
              </div>
              <button @click="addCart(false)" type="button" class="btn btn-white_line p">加入購物車</button>
            </div>
          </div>
          <router-link
            class="see_more txt-white"
            :to="{
              name: 'ProductInfo',
              params: { id: item.id },
              query: { code: marketCode },
            }">
              <p>查看詳情</p>
          </router-link>
        </form>
      </div>
    </div>
  </div>
</template>

<script>
import { mapState } from 'vuex';
import moment from 'moment';
import requestApi from '@/lib/http/index';
import ProductItemSwiper from '@/components/product/ProductItemSwiper.vue';

export default {
  name: 'ProductItem',
  props: ['item', 'category', 'brandOption'],
  components: {
    ProductItemSwiper,
  },
  data() {
    return {
      count: 1,
      color: null,
      brand: null,
      brandIndex: 0,
      model: null,
      ifFav: false,
      swiperOption: {
        loop: true,
        allowTouchMove: true,
        navigation: {
          nextEl: '#product .item .item_show .travel_pic .arrow',
        },
      },
      marketCode: '',
    };
  },
  methods: {
    getDays(abroadStart, abroadEnd) {
      const startDay = moment(abroadStart, 'YYYY-MM-DD');
      const endDay = moment(abroadEnd, 'YYYY-MM-DD');
      const days = endDay.diff(startDay, 'days') + 1;
      return days;
    },
    selectBrand(e) {
      this.brand = e.target.value;
      this.brandOption.forEach((item, i) => {
        if (item.id === e.target.value) {
          this.brandIndex = i;
          if (item.model[0]) {
            this.model = item.model[0].id;
          }
        }
      });
    },
    async checkEnoughStock({
      // eslint-disable-next-line camelcase
      abroad_start = this.toolSearch.dateRange[0],
      // eslint-disable-next-line camelcase
      abroad_end = this.toolSearch.dateRange[1],
      // eslint-disable-next-line camelcase
      product_id = this.item.id,
      qty = this.count,
      code = this.marketCode || null,
    } = {}) {
      const { status, message } = await requestApi('cart.checkEnoughStock', {
        abroad_start,
        abroad_end,
        product_id,
        qty,
        code,
      });
      return { status, message };
    },
    async addCart(toCart = false) {
      if (this.isLogin) {
        if (this.isMemberInfo) {
          const formDataProcess = new Promise((resolve, reject) => {
            const formData = {
              member_id: this.user.id,
            };
            if (parseInt(this.category, 10) === 1) {
              if (this.toolSearch.dateRange.length > 0) {
                if (this.toolSearch.dateRange[0] && this.toolSearch.dateRange[1]) {
                  const [abroadStart, abroadEnd] = this.toolSearch.dateRange;
                  formData.abroad_start = moment(abroadStart).format('YYYY/MM/DD');
                  formData.abroad_end = moment(abroadEnd).format('YYYY/MM/DD');
                  formData.days = this.getDays(abroadStart, abroadEnd);
                  if (this.item.type === '出租品') {
                    formData.total = this.item.sell_price * this.count * formData.days;
                  } else {
                    formData.total = this.item.sell_price * this.count;
                  }
                  if (this.item.bird_day) {
                    if (
                      !(Date.parse(moment().add(this.item.bird_day - 1, 'days')) < Date.parse(moment(abroadStart)))
                    ) {
                      // eslint-disable-next-line
                      reject({
                        message: `早鳥優惠起始日需在 ${moment().add(this.item.bird_day, 'days').format('YYYY/MM/DD')} 之後才能預約`,
                      });
                    }
                  }
                } else {
                  // eslint-disable-next-line
                  reject({
                    message: '請在上方欄位填寫出國時間',
                    fn: () => { window.scrollTo({ top: 0, behavior: 'smooth' }); },
                  });
                }
              } else {
                // eslint-disable-next-line
                reject({
                  message: '請在上方欄位填寫出國時間',
                  fn: () => { window.scrollTo({ top: 0, behavior: 'smooth' }); },
                });
              }
            } else if (parseInt(this.category, 10) === 2) {
              if (this.color) {
                formData.color_id = this.color;
              }
              formData.total = this.item.sell_price * this.count;
            }
            if (this.marketCode) {
              formData.code = this.marketCode;
            }
            if (this.item.category === 'eSIM') {
              formData.brand_id = this.brand;
              formData.mobile_model_id = this.model;
            }
            if (this.toolSearch.targetCountry && this.toolSearch.targetCountry.length > 0) {
              const country = this.toolSearch.targetCountry[0];
              formData.country_id = country;
            }
            formData.product_id = this.item.id;
            formData.price = this.item.price;
            formData.sell_price = this.item.sell_price;
            formData.qty = this.count;
            resolve(formData);
          });

          const { status, message } = await this.checkEnoughStock();
          formDataProcess.then((form) => {
            if (status) {
              const addCart = requestApi('cart.addCart', form);
              addCart.then((result) => {
                if (result.status) {
                  // const { cart } = this;
                  // this.setCart(cart + 1);
                  if (toCart) {
                    this.$router.push({ name: 'CartInfo' });
                  } else {
                    this.$customSWAL({
                      icon: 'success',
                      title: 'Success',
                      text: '加入購物車成功',
                      confirmButtonText: '確定',
                      thenFunc: () => {
                        this.$router.go();
                      },
                    });
                  }
                } else {
                  this.$customSWAL({
                    icon: 'error',
                    title: '錯誤',
                    text: result.message,
                    confirmButtonText: '確定',
                  });
                }
              });
            } else {
              this.$customSWAL({
                icon: 'info',
                title: message,
              });
            }
          }).catch((err) => {
            this.$customSWAL({
              icon: 'info',
              title: '提示',
              text: err.message,
              confirmButtonText: '確定',
              thenFunc: err.fn || null,
            });
          });
        } else {
          this.$customSWAL({
            icon: 'error',
            title: '錯誤',
            text: '請先填寫會員資料',
            confirmButtonText: '確定',
            thenFunc: () => {
              this.$router.push({
                name: 'MemberData',
              });
            },
          });
        }
      } else {
        this.$customSWAL({
          icon: 'info',
          title: '提示',
          text: '請先登入帳號',
          confirmButtonText: '確定',
          thenFunc: () => {
            this.$router.push({ path: '/Login', query: { pathname: window.location.pathname, search: window.location.search } });
          },
        });
      }
    },
    async updateFav() {
      if (!this.user.id) {
        this.$customSWAL({
          icon: 'error',
          title: '請先登入',
        });
      } else {
        const { status, message } = await requestApi('member.addFavorite', {
          member_id: this.user.id,
          product_id: this.item.id,
        });

        if (status) {
          this.$customSWAL({
            title: message,
          });
        }
      }
      this.ifFav = !this.ifFav;
    },
    async addCount() {
      const { status, message } = await this.checkEnoughStock({ qty: this.count + 1 });
      if (status) {
        if (this.item.purchase_limit) {
          if (this.count < this.item.purchase_limit) {
            this.count += 1;
          } else {
            this.$customSWAL({
              icon: 'info',
              title: '超過購買上限',
            });
          }
        } else {
          this.count += 1;
        }
      } else {
        this.$customSWAL({
          icon: 'info',
          title: message,
          thenFunc: () => {
            if (!this.toolSearch.dateRange[0] || !this.toolSearch.dateRange[1]) {
              if (message !== '庫存不足') {
                window.scrollTo({ top: 0, behavior: 'smooth' });
              }
            }
          },
        });
      }
    },
  },
  computed: {
    ...mapState([
      'toolSearch',
      'isLogin',
      'user',
      'isMemberInfo',
    ]),
  },
  watch: {
    $props: {
      handler() {
        if (this.item.colors && this.item.colors.length > 0) {
          this.color = this.item.colors[0].id;
        }
        if (this.brandOption && this.brandOption.length > 0) {
          if (this.brandOption[0]) {
            this.brand = this.brandOption[0].id;
            if (this.brandOption[0].model[0]) {
              this.model = this.brandOption[0].model[0].id;
            }
          }
        }
      },
      deep: true,
      immediate: true,
    },
  },
  mounted() {
    this.marketCode = window.localStorage.getItem('marketCode') || '';

    this.ifFav = this.item.is_tracking;
  },
};
</script>
