<template>
  <div>
    <div class="title">
        <h3 class="h2-super_title txt-bold">
            租退換貨規則 <img src="@/assets/img/icon/icon_exclamation.svg" alt="">
        </h3>
    </div>

    <div class="itembox">
        <div class="item" v-for="(item, i) in itemsArray" :key="`item_${i}`">
        <div class="top" @click="item.show = !item.show">
            <div class="top_head">
            <img src="@/assets/img/icon/-.svg" alt="" class="del" v-if="item.show">
            <img src="@/assets/img/icon/+.svg" alt="" class="add" v-else>
            <h6>{{item.title}}</h6>
            </div>
            <img src="@/assets/img/icon/icon_wave_b.svg" class="wave" alt="">
        </div>
        <div class="content" :class="{ active: item.show }" v-html="item.content" />
        </div>
    </div>
    <p class="txt-greenlake txt-right">2013 年 08 月 29 日 制定、施行</p>
  </div>
</template>
<script>
export default {
  name: 'TurnBack',
  data() {
    return {
      itemsArray: [
        {
          show: false,
          title: '宅配、超商取件，享七天鑑賞期',
          content: `<p>
                    ▲依《消費者保護法》規定，消費者享有商品貨到日起七天鑑賞期的權益。<br>
                    提醒大家，猶豫期無法提供「試用」，請保持商品發票、包裝、本體、配件、說明書...等為全新完整狀態，如有遺失、缺件或損毀，將可能影響您的退貨權益，再請諒解。<br><br>
                    ▲消費者購買商品將於付款後3個工作天內出貨，並擁有七日鑑賞期皆可申請商品退/換貨。如非瑕疵品，退換貨需自行負擔運費。詳細說明如下：<br><br>
                    ▲瑕疵品<br>
                    如您收到之商品有非人為因素，損毀、刮傷、包裝破損...等狀況，請與Wi-Ho！客服聯繫。
                    <br>Wi-Ho！進行瑕疵鑑定、損壞檢測後，將會盡速更換新品，或協助辦理退款。若商品經檢測後並無瑕疵，消費者須負擔過程中所產生之費用。<br><br>
                    ▲非瑕疵品（需自行負擔運費）<br>
                    若您有非瑕疵品退貨需求，請與Wi-Ho！客服聯繫，將協助取消訂單。<br>
                    請於卡片效期前20日寄出，若於卡片效期20日內寄出，則不接受退款。（寄出日期以郵戳日期為憑）
                    寄件地址為「105 台北市松山區民權東路三段181號8樓 特樂通股份有限公司 收」。<br>
                    Wi-Ho！收到您的商品後，將會盡速為您處理，並於10個工作天內為您完成退款作業。<br><br>
                    ▲卡片開通後，不適用7日鑑賞期退/換貨準則<br>
                    依消費者保護法第19條第1項通訊交易解除權，sim卡商品一經開通，不適用7日鑑賞期退/換貨準則。<br><br>
                    <span class="txt-greenlake">※即期卡片不接受退款，下訂前請務必確認。</span>
                </p>`,
        },
        {
          show: false,
          title: '機場櫃台取件，無鑑賞期之退換貨規則',
          content: `<p>▲桃園機場取卡<br>
                        尚未取卡：可於出國日前3天(不含出國日)免費辦理退卡，3天內取消，將酌收取消費$60元。若卡片已為10日內到期之效期，則不接受退款。（如因物流未配卡導致退卡情況，則不在此限）。<br>
                        已取卡：一律不接受退換貨。<br><br>

                        ▲小港、松山機場取卡<br>
                        尚未取卡：可於出國日前5天(不含出國日)免費辦理退卡，5天內取消酌收取消費$60元。若卡片已為10日內到期之效期，則不接受退款。（如因物流未配卡導致退卡情況，則不在此限）。<br>
                        已取卡：一律不接受退換貨。<br>
                    </p>`,
        },
        {
          show: false,
          title: '便利商店/宅配/機場櫃台未取件說明',
          content: `<p>
                        消費者若因為以下狀況未取件：<br>
                        1. 未於貨到後7日內至指定門市取件。<br>
                        2. 宅配到貨時未取件或拒絕收件。<br>
                        3. 機場櫃台未於預定出國日當天取件。<br>
                        貨品將自動退回本公司，訂單視為取消，並酌收未取件服務費60元，其餘費用協助辦理退款。
                    </p>`,
        },
        {
          show: false,
          title: '聯絡客服',
          content: `<p>
                        您可使用Wi-Ho！官網的留言功能與我們聯繫，亦可透過下列方式來電/來信Wi-Ho！客服。<br><br>
                        • 客服時間：週一至週日，9:00-18:00<br>
                        • 客服電話：02-2545-7777<br>
                        • 客服信箱：service@tswiho.com<br>
                        • 線上客服：@wiho
                    </p>`,
        },
      ],
    };
  },
};
</script>
