var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('div',{staticClass:"fixed_circle active",class:{
      unactive: _vm.$route && _vm.$route.name && (_vm.$route.name.indexOf('Cart') >= 0 ||
      _vm.$route.name.indexOf('Login') >= 0 ||
      _vm.$route.name.indexOf('Register') >= 0 ||
      _vm.$route.name.indexOf('ForgetPassword') >= 0 ||
      _vm.$route.name.indexOf('Member') >= 0)
    },attrs:{"id":"to_top"}},[_c('img',{attrs:{"src":require("@/assets/img/icon/top_wave.svg"),"alt":""},on:{"click":_vm.scrollToTop}})]),_c('footer',[_c('div',{staticClass:"inner w1300"},[_c('router-link',{staticClass:"logo",attrs:{"to":"/"}},[_c('img',{attrs:{"src":require("@/assets/img/logo.png"),"alt":""}})]),_c('nav',{staticClass:"nav"},[_c('div',{staticClass:"footer_nav_item"},[_c('h6',{staticClass:"sub_title txt-bold"},[_vm._v("Wi-Ho!產品")]),_c('router-link',{staticClass:"sub_link",attrs:{"to":{
              name: 'Product',
              params: { category: 1 },
              query: { productCategory: 1, sort: 1, code: null || this.$route.query.code },
            }}},[_c('p',[_vm._v("WiFi分享器")])]),_c('router-link',{staticClass:"sub_link",attrs:{"to":{
              name: 'Product',
              params: { category: 1 },
              query: { productCategory: 2, sort: 1, code: null || this.$route.query.code },
            }}},[_c('p',[_vm._v("SIM卡")])]),_c('router-link',{staticClass:"sub_link",attrs:{"to":{
              name: 'Product',
              params: { category: 1 },
              query: { productCategory: 3, sort: 1, code: null || this.$route.query.code },
            }}},[_c('p',[_vm._v("eSIM")])]),_c('router-link',{staticClass:"sub_link",attrs:{"to":{
              name: 'Product',
              params: { category: 2 },
              query: { productCategory: 0, sort: 1, code: null || this.$route.query.code },
            }}},[_c('p',[_vm._v("旅遊周邊")])])],1),_c('div',{staticClass:"footer_nav_item"},[_c('h6',{staticClass:"sub_title txt-bold"},[_vm._v("關於Wi-Ho!")]),_c('router-link',{staticClass:"sub_link",attrs:{"to":"/About"}},[_c('p',[_vm._v("公司介紹")])]),_c('router-link',{staticClass:"sub_link",attrs:{"to":"/About?history=1"}},[_c('p',[_vm._v("公司沿革")])])],1),_c('div',{staticClass:"footer_nav_item"},[_c('h6',{staticClass:"sub_title txt-bold"},[_vm._v("最新活動")]),_c('li',[_c('router-link',{attrs:{"to":{
                  name: 'Events',
                  query: { category: '', page: '1' },
                }}},[_vm._v(" 活動資訊 ")])],1),_c('li',[_c('router-link',{attrs:{"to":{
                  name: 'News',
                  query: { category: '', page: '1' },
                }}},[_vm._v(" 最新消息 ")])],1)]),_c('div',{staticClass:"footer_nav_item"},[_c('h6',{staticClass:"sub_title txt-bold"},[_vm._v("機場櫃台")]),_c('router-link',{staticClass:"sub_link",attrs:{"to":"/Airport/1"}},[_c('p',[_vm._v("桃園國際機場")])]),_c('router-link',{staticClass:"sub_link",attrs:{"to":"/Airport/2"}},[_c('p',[_vm._v("台北松山機場")])]),_c('router-link',{staticClass:"sub_link",attrs:{"to":"/Airport/3"}},[_c('p',[_vm._v("高雄小港機場")])])],1),_c('div',{staticClass:"footer_nav_item"},[_c('h6',{staticClass:"sub_title txt-bold"},[_vm._v("企業專區")]),_c('router-link',{staticClass:"sub_link",attrs:{"to":"/CompanyArea"}},[_c('p',[_vm._v("企業服務")])]),_c('a',{staticClass:"sub_link",staticStyle:{"cursor":"pointer"},on:{"click":function($event){return _vm.companyLightboxStatusChange(true);}}},[_c('p',[_vm._v("洽詢商務合作")])])],1),_c('div',{staticClass:"footer_nav_item"},[_c('h6',{staticClass:"sub_title txt-bold"},[_vm._v("客服中心")]),_c('router-link',{staticClass:"sub_link",attrs:{"to":"/Faq/1"}},[_vm._v(" 常見問題 ")])],1),_c('div',{staticClass:"footer_nav_item"},[_c('h6',{staticClass:"sub_title txt-bold"},[_vm._v("生活講堂")]),_c('router-link',{staticClass:"sub_link",attrs:{"to":{
              name: 'Blog',
              query: { category: '', page: '1' },
            }}},[_c('p',[_vm._v("Wi-Ho! Blog")])]),_c('a',{staticClass:"sub_link",staticStyle:{"cursor":"pointer"},on:{"click":function($event){return _vm.coopLightboxStatusChange(true);}}},[_c('p',[_vm._v("異業合作")])])],1)])],1),_c('div',{staticClass:"footer_more w1300"},[_c('small',{staticClass:"more_set"},[_c('router-link',{attrs:{"to":"/Terms/Copyright"}},[_vm._v("著作權聲明")]),_vm._v(" |  "),_c('router-link',{attrs:{"to":"/Terms/Privacy"}},[_vm._v("隱私權聲明")]),_vm._v(" |  "),_c('router-link',{attrs:{"to":"/Terms/Rent"}},[_vm._v("租賃條款")]),_vm._v(" |  "),_c('router-link',{attrs:{"to":"/Terms/TurnBack"}},[_vm._v("退貨說明")]),_vm._v(" |  "),_c('a',{attrs:{"href":"https://www.104.com.tw/company/1a2x6bint0?jobsource=2018indexpoc"}},[_vm._v("人才招募")]),_vm._v(" | "),_vm._m(0),_vm._m(1)],1)])]),_vm._m(2)])}
var staticRenderFns = [function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('a',{attrs:{"href":"https://line.me/R/ti/p/@wiho","target":"_blank"}},[_c('img',{attrs:{"src":require("@/assets/img/icon/navbar_line.svg"),"alt":""}})])},function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('a',{attrs:{"href":"https://www.facebook.com/wihotw","target":"_blank"}},[_c('img',{attrs:{"src":require("@/assets/img/icon/navbar_fb.svg"),"alt":""}})])},function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{attrs:{"id":"copyright"}},[_c('div',{staticClass:"inner w1300 txt-white"},[_c('small',[_c('img',{attrs:{"src":require("@/assets/img/index/icon_phone.svg"),"alt":""}}),_vm._v(" 02-2545-7777 "),_c('img',{staticStyle:{"margin-left":"10px"},attrs:{"src":require("@/assets/img/index/icon_mail.svg"),"alt":""}}),_vm._v(" service@tswiho.com ")]),_c('small',[_vm._v(" Copyright © Telecom Square Taiwan, Inc. All right reserved. ")])])])}]

export { render, staticRenderFns }