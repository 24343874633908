<template>
  <div id="airport">
    <Header/>

    <div id="service_top">
      <div class="inner w1300 txt-white">
        <div class="title txt-center sr_right">
          <h2 class="h2-super_title txt-bold">
            業界第一家進駐桃園機場<br> 擁有最多服務櫃檯 <img src="@/assets/img/icon/icon_exclamation-w.svg" alt="">
          </h2>
        </div>
        <div class="flex  sr_right">
          <div class="btn btn-white_outline haslightbox" @click="best4lightbox=true">
            <p>Wi-Ho! 機場櫃檯 4 大優勢</p>&nbsp;
            <svg version="1.1" id="圖層_1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px" width="6.664px" height="10.072px" viewBox="0 0 6.664 10.072" enable-background="new 0 0 6.664 10.072" xml:space="preserve">
              <path id="Path_29" fill="#FFFFFF" :d="topSvg" />
            </svg>
          </div>
        </div>
      </div>
    </div>

    <div id="step">
      <div class=" w1400 breadcrumbs">
        <span>
          <router-link to="/">首頁</router-link>
        </span> > 機場櫃位 · 門市
      </div>
      <div class="inner w1400">
        <div class="bg">
            <div
              class="bg2"
              :style="`background-image: url(${require('@/assets/img/feedback/deco.png')})`">
            </div>
        </div>

        <ArticleTag
          :categoryArray="categoryArray"
          :ifAirport="true"/>

        <div class="item_box">
          <!-- 桃園國際機場 -->
          <div class="item active" v-if="Number(loc)===1">
            <div class="top_head">
              <h6
                :class="{active: nowShow === 1}"
                class="list_name"
                @click="changeSub(1)">
                  第①航廈：入境大廳
              </h6>
              <h6
                :class="{active: nowShow === 2}"
                class="list_name"
                @click="changeSub(2)">
                  第①航廈：出境大廳-宅配通取機櫃台
              </h6>
              <h6
                :class="{active: nowShow === 3}"
                class="list_name"
                @click="changeSub(3)">
                  第②航廈：入境大廳
              </h6>
              <h6
                :class="{active: nowShow === 4}"
                class="list_name"
                @click="changeSub(4)">
                  第②航廈：入境大廳-宅配通取機櫃台
              </h6>
            </div>
            <div
              class="content"
              :class="{active: nowShow === 1}">
                <h4>第1航廈：1樓入境大廳-①號WiFi分享器櫃台</h4>
                <div class="info">
                    <h6 class="title">
                      <img src="@/assets/img/icon/icon_location.svg" alt="">櫃台場所
                    </h6>
                    <h6 class="inside">出海關後左轉走到底， <span class="circle">1</span>號WiFi分享器櫃台</h6>
                    <h6 class="title"><img src="@/assets/img/icon/icon_info.svg" alt="">服務項目</h6>
                    <h6 class="inside">預約取件｜還機｜設置24小時還機箱</h6>
                    <h6 class="title"><img src="@/assets/img/icon/icon_time.svg" alt="">營業時間</h6>
                    <h6 class="inside">10:00 ~ 19:00（全年無休）</h6>
                </div>
                <div class="pic_group">
                  <div class="pic">
                    <figure
                      :style="`background-image: url(${require('@/assets/img/store/store.png')})`">
                    </figure>
                  </div>
                  <div class="pic">
                    <figure
                      :style="`background-image: url(${require('@/assets/img/store/map-01.jpg')})`">
                    </figure>
                  </div>
                </div>
                <div class="flex_zoom">
                  <img src="@/assets/img/icon/icon_zoom.svg" alt="" class="haslightbox">
                </div>
            </div>
            <div
              class="content"
              :class="{active: nowShow === 2}">
                <h4>第1航廈：1樓出境大廳-宅配通取件櫃台</h4>
                <div class="info">
                  <h6 class="title"><img src="@/assets/img/icon/icon_location.svg" alt="">櫃台場所</h6>
                  <h6 class="inside">北側，靠近12號報到櫃台</h6>
                  <h6 class="title"><img src="@/assets/img/icon/icon_info.svg" alt="">服務項目</h6>
                  <h6 class="inside">預約取件</h6>
                  <h6 class="title"><img src="@/assets/img/icon/icon_time.svg" alt="">營業時間</h6>
                  <h6 class="inside">班機時間21:00 ~ 12:00 於此櫃台取件（全年無休）</h6>
                </div>
                <div class="pic_group">
                  <div class="pic">
                    <figure
                      :style="
                        `background-image: url(${require('@/assets/img/store/t_air_new.jpg')})`
                      ">
                    </figure>
                  </div>
                  <div class="pic">
                    <figure
                      :style="`background-image: url(${require('@/assets/img/store/map-03.jpg')})`">
                    </figure>
                  </div>
                </div>
                <div class="flex_zoom">
                  <img src="@/assets/img/icon/icon_zoom.svg" alt="" class="haslightbox">
                </div>
            </div>
            <div
              class="content"
              :class="{active: nowShow === 3}">
                <h4>第2航廈：1樓入境大廳-①號WiFi分享器櫃台</h4>
                <div class="info">
                  <h6 class="title"><img src="@/assets/img/icon/icon_location.svg" alt="">櫃台場所</h6>
                  <h6 class="inside">出海關後右轉，<span class="circle">1</span>號WiFi分享器櫃台</h6>
                  <h6 class="title"><img src="@/assets/img/icon/icon_info.svg" alt="">服務項目</h6>
                  <h6 class="inside">預約取件｜還機｜設置24小時還機箱</h6>
                  <h6 class="title"><img src="@/assets/img/icon/icon_time.svg" alt="">營業時間</h6>
                  <h6 class="inside">10:00 ~ 19:00（全年無休）</h6>
                </div>
                <div class="pic_group">
                  <div class="pic">
                    <figure
                      :style="
                        `background-image: url(${require('@/assets/img/store/t-star-2.png')})`
                      ">
                    </figure>
                  </div>
                  <div class="pic">
                    <figure
                      :style="`background-image: url(${require('@/assets/img/store/map-02.jpg')})`">
                    </figure>
                  </div>
                </div>
                <div class="flex_zoom">
                  <img src="@/assets/img/icon/icon_zoom.svg" alt="" class="haslightbox">
                </div>
            </div>
            <div
              class="content"
              :class="{active: nowShow === 4}">
                <h4>第2航廈：1樓入境大廳-宅配通取件櫃台</h4>
                <div class="info">
                  <h6 class="title"><img src="@/assets/img/icon/icon_location.svg" alt="">櫃台場所</h6>
                  <h6 class="inside">第二航廈1樓入境大廳南側（電信櫃台旁）</h6>
                  <h6 class="title"><img src="@/assets/img/icon/icon_info.svg" alt="">服務項目</h6>
                  <h6 class="inside">預約取件</h6>
                  <h6 class="title"><img src="@/assets/img/icon/icon_time.svg" alt="">營業時間</h6>
                  <h6 class="inside">班機時間21:00 ~ 12:00 於此櫃台取件（全年無休）</h6>
                </div>
                <div class="pic_group">
                  <div class="pic">
                    <figure
                      :style="`background-image: url(${require('@/assets/img/store/t_air.jpg')})`">
                    </figure>
                  </div>
                  <div class="pic">
                    <figure
                      :style="`background-image: url(${require('@/assets/img/store/map-04.jpg')})`">
                    </figure>
                  </div>
                </div>
                <div class="flex_zoom">
                  <img src="/img/icon/icon_zoom.svg" alt="" class="haslightbox">
                </div>
            </div>
          </div>
          <!-- 台北松山機場 -->
          <div class="item active" v-else-if="Number(loc)===2">
            <div class="top_head">
              <h6
                :class="{active: nowShow === 1}"
                class="list_name"
                @click="changeSub(1)">
                  第①航廈：入境大廳
              </h6>
              <!-- <h6 class="list_name">第①航廈：出境大廳-宅配通取機櫃台</h6> -->
            </div>
            <div
              :class="{active: nowShow === 1}"
              class="content">
                <h4>第1航廈：1樓國際線入境大廳</h4>
                <div class="info">
                  <h6 class="title">
                    <img src="@/assets/img/icon/icon_location.svg" alt="">櫃台場所
                  </h6>
                  <h6 class="inside">國際線入境大廳正對面，交通部觀光局櫃台旁（鄰近E門）</h6>
                  <h6 class="title"><img src="@/assets/img/icon/icon_info.svg" alt="">服務項目</h6>
                  <h6 class="inside">預約取件｜臨櫃租借｜還機｜設置24小時還機箱</h6>
                  <h6 class="title"><img src="@/assets/img/icon/icon_time.svg" alt="">營業時間</h6>
                  <h6 class="inside">08:00 ~ 17:00（全年無休）</h6>
                </div>
                <div class="pic_group">
                  <div class="pic">
                    <figure
                      :style="
                        `background-image: url(${require('@/assets/img/store/s_airport.jpg')})`
                      ">
                    </figure>
                  </div>
                  <div class="pic">
                    <figure
                      :style="
                        `background-image: url(${require('@/assets/img/store/map-05.jpg')})`
                      ">
                    </figure>
                  </div>
                </div>
                <div class="flex_zoom">
                  <img src="@/assets/img/icon/icon_zoom.svg" alt="" class="haslightbox">
                </div>
            </div>
            <!-- <div class="content">
                <h4>第1航廈：1樓國際線入境大廳 -宅配通取件櫃台</h4>
                <div class="info">
                  <h6 class="title"><img src="/img/icon/icon_location.svg" alt="">櫃台場所</h6>
                  <h6 class="inside">國際線入境大廳，鄰近E門。</h6>
                  <h6 class="title"><img src="/img/icon/icon_info.svg" alt="">服務項目</h6>
                  <h6 class="inside">預約取件</h6>
                  <h6 class="title"><img src="/img/icon/icon_time.svg" alt="">營業時間</h6>
                  <h6 class="inside">
                    班機時間 18:00～09:00 於此櫃檯取件 （全年無休）<br>
                    <span style="color:#4DADA7">2020/3/11起，暫停取件服務</span>
                  </h6>
                </div>

                <div class="pic_group">
                  <div class="pic">
                    <figure style="background-image: url(/img/store/s_airport_2.jpg)"></figure>
                  </div>
                  <div class="pic">
                    <figure style="background-image: url(/img/store/map-06.jpg)"></figure>
                  </div>
                </div>
                <div class="flex_zoom">
                  <img src="/img/icon/icon_zoom.svg" alt="" class="haslightbox">
                </div>
            </div> -->
          </div>
          <!-- 高雄小港機場 -->
          <div class="item active" v-else-if="Number(loc)===3">
            <div class="content active">
              <h4>國際線航廈：1樓入境大廳</h4>
              <div class="info">
                <h6 class="title"><img src="@/assets/img/icon/icon_location.svg" alt="">櫃台場所</h6>
                <h6 class="inside">出海關後直走，中華電信櫃台內</h6>
                <h6 class="title"><img src="@/assets/img/icon/icon_info.svg" alt="">服務項目</h6>
                <h6 class="inside">預約取件｜臨櫃租借｜還機｜設置24小時還機箱</h6>
                <h6 class="title"><img src="@/assets/img/icon/icon_time.svg" alt="">營業時間</h6>
                <h6 class="inside">10:00 ~ 19:00（全年無休）</h6>
              </div>
              <div class="pic_group">
                <div class="pic">
                  <figure
                    :style="
                      `background-image: url(${require('@/assets/img/store/k_airport.jpg')})`
                    ">
                  </figure>
                </div>
                <div class="pic">
                  <figure
                    :style="`background-image: url(${require('@/assets/img/store/map-07.jpg')})`">
                  </figure>
                </div>
              </div>
              <div class="flex_zoom">
                <img src="@/assets/img/icon/icon_zoom.svg" alt="" class="haslightbox">
              </div>
            </div>
          </div>
          <!-- 合作門市 -->
          <div class="item active" v-else-if="Number(loc)===4">
          </div>
        </div>

      </div>
    </div>

    <section class="checked_lightbox" :class="{active: best4lightbox}">
      <div class="back_cover" @click="best4lightbox=false"></div>
      <div class="box">
        <img src="@/assets/img/icon/icon_X.svg" class="close close_x" @click="best4lightbox=false">
        <div class="item_box">
          <div class="item">
            <img src="@/assets/img/store/pop_1.svg" alt="" class="icon">
            <div class="txt">
              <h5 class="title">
                無預約時臨櫃租借OK <img src="@/assets/img/icon/icon_exclamation.svg" alt="">
              </h5>
              <p>若無事先預約，出國當日可直接於松山機場、小港機場Wi-Ho!櫃台臨櫃租借。(庫存依現場為主)</p>
              <small>※若您有臨時的訂機需求，且於松山機場或小港機場出境，最晚可於「出國前一日」來電急件預約。</small>
              <a href="service.php">服務詳情<img src="@/assets/img/icon/icon_next.svg" alt=""></a>
            </div>
          </div>
          <div class="item">
            <img src="@/assets/img/store/pop_2.svg" alt="" class="icon">
            <div class="txt">
              <h5 class="title">
                專業客服人員說明<img src="@/assets/img/icon/icon_exclamation.svg" alt="">
              </h5>
              <p>台北松山機場、高雄小港機場Wi-Ho!櫃台皆有專業客服，提供您各項商品諮詢、取機測試、操作教學等服務。</p>
            </div>
          </div>
          <div class="item">
            <img src="@/assets/img/store/pop_3.svg" alt="" class="icon">
            <div class="txt">
              <h5 class="title">
                機場取機，現正免運中<img src="@/assets/img/icon/icon_exclamation.svg" alt="">
              </h5>
              <p>機場取機免運費、免手續費，划算又方便!</p>
            </div>
          </div>
          <div class="item">
            <img src="@/assets/img/store/pop_4.svg" alt="" class="icon">
            <div class="txt">
              <h5 class="title">
                24HR便利還機<img src="@/assets/img/icon/icon_exclamation.svg" alt="">
              </h5>
              <p>各Wi-Ho!櫃台前皆設有還機箱。還機超便利!</p>
            </div>
          </div>
        </div>
      </div>
    </section>

  </div>
</template>

<script>
// @ is an alias to /src
import Header from '@/components/Header.vue';
import ArticleTag from '@/components/article/ArticleTag.vue';

import '@/assets/scss/airport.scss';

export default {
  name: 'Airport',
  props: ['loc'],
  components: {
    Header,
    ArticleTag,
  },
  data() {
    return {
      nowShow: 1,
      categoryArray: [
        {
          name: '桃園國際機場',
          link: '/Airport/1',
        },
        {
          name: '台北松山機場',
          link: '/Airport/2',
        },
        {
          name: '高雄小港機場',
          link: '/Airport/3',
        },
        {
          name: '合作門市',
          link: '/Airport/4',
        },
      ],
      best4lightbox: false,
      topSvg: 'M0.148,0.016c1.186,0.378,2.304,0.938,3.316,1.662c0.973,0.669,1.883,1.425,2.719,2.259l0.009,0.009c0.613,0.592,0.631,1.569,0.04,2.183c-0.001,0-0.001,0-0.001,0L6.183,6.177c-0.832,0.822-1.736,1.568-2.7,2.23c-1.005,0.715-2.115,1.271-3.289,1.648l-0.041,0.012c-0.042,0.012-0.086-0.013-0.1-0.055C0.044,9.984,0.052,9.952,0.072,9.93l4.877-5v0.246L0.032,0.137C0,0.105-0.001,0.052,0.031,0.02c0,0,0-0.001,0.001-0.001C0.053-0.002,0.084-0.01,0.113,0L0.148,0.016z',
    };
  },
  methods: {
    changeSub(num) {
      this.nowShow = num;
    },
  },
  watch: {
    $route() {
      this.changeSub(1);
    },
  },
};
</script>
