<template>
  <div class="home">
    <Header/>

    <!-- kv banner -->
    <IndexKv
      :kvData="banners"/>

    <IndexEasy/>

    <!-- 純文案區塊 - Wi-Ho! 專屬好康 -->
    <section id="go_abroad_top">
      <div class="top main_padding">
        <div class="w1300">
          <h2
            v-scroll-reveal="$sr_rightSetting"
            class="h2-super_title txt-bold">
              <span class="txt-highbold txt-style-i">Wi-Ho!</span> 專屬好康
              <img src="@/assets/img/icon/icon_exclamation.svg" alt="">
          </h2>
        </div>
      </div>
    </section>

    <!-- 廣告連結輪播區01 -->
    <IndexGoAbroad
      v-if="eventData1.length"
      :eventData="eventData1"/>

    <!-- 租借購買流程 -->
    <IndexConvenient/>

    <!-- 最新消息 -->
    <PublicDiscountSection
      v-if="lastNews.length > 0"
      :link="'/News?category=&page=1'"
      :newsData="lastNews"/>

    <!-- 廣告連結輪播區02 -->
    <IndexGoAbroad
      v-if="eventData2.length > 0"
      :eventData="eventData2"/>

    <!-- 6項服務區塊 -->
    <IndexFullUse/>

    <!-- 客戶好評 -->
    <IndexChooseWiho
      :feedbacks="feedbacks"/>

    <!-- 純文案區塊 - 輕鬆連線跟著走 -->
    <section id="enjoy" class="main_padding">
      <div class="inner w1300">
        <h2
          v-scroll-reveal="$sr_rightSetting"
          class="left h2-super_title txt-bold txt-blue">
            <span class="txt-style-i txt-highbold">
              Enjoy your trip with
            </span>&nbsp;
            <img src="@/assets/img/logo.png" alt="" style="height: .7em; width: auto;">
            <br>
            輕鬆連線跟著走
            <img src="@/assets/img/icon/icon_exclamation.svg" alt="">
        </h2>
        <div class="right wifi_machine">
          <img class="wave" src="@/assets/img/index/img_wifi_wave.svg" alt="">
          <img class="machine" src="@/assets/img/index/img_wifi.png" alt="">
        </div>
      </div>
    </section>
  </div>
</template>

<script>
// @ is an alias to /src
import Header from '@/components/Header.vue';
import IndexKv from '@/components/index/IndexKv.vue';
import IndexEasy from '@/components/index/IndexEasy.vue';
import IndexConvenient from '@/components/index/IndexConvenient.vue';
import PublicDiscountSection from '@/components/PublicDiscountSection.vue';
import IndexGoAbroad from '@/components/index/IndexGoAbroad.vue';
import IndexChooseWiho from '@/components/index/IndexChooseWiho.vue';
import IndexFullUse from '@/components/index/IndexFullUse.vue';

import requestApi from '@/lib/http/index';

import '@/assets/scss/index.scss';

export default {
  name: 'Home',
  components: {
    Header,
    IndexKv,
    IndexEasy,
    IndexConvenient,
    PublicDiscountSection,
    IndexGoAbroad,
    IndexChooseWiho,
    IndexFullUse,
  },
  data() {
    return {
      banners: [],
      lastNews: [],
      feedbacks: [],
      eventData1: [],
      eventData2: [],
    };
  },
  methods: {
    async getBanners() {
      this.banners = [];

      const result = await requestApi('home.getBanners');

      if (result?.data?.forEach) {
        result.data.forEach((element) => {
          const item = {
            id: element.id,
            bg: element.pc_img,
            bgMobile: element.mobile_img,
            link: element.url,
          };
          this.banners.push(item);
        });
      }
    },

    async getLastNews() {
      this.lastNews = [];

      const result = await requestApi('home.getLastNews');

      if (result?.data?.forEach) {
        result.data.forEach((element) => {
          const item = {
            route: {
              name: 'NewsInfo',
              params: {
                id: element.id,
              },
            },
            type: element.category,
            date: element.publish_day,
            title: element.title,
          };
          this.lastNews.push(item);
        });
      }
    },

    async getQuestionnaires() {
      this.feedback = [];

      const result = await requestApi('home.getQuestionnaires');

      if (result.data) {
        const arr = [];
        if (result?.data?.forEach) {
          result.data.forEach((element) => {
            const item = {
              rate: Number(element.star),
              info: element.reason,
              product: element.product,
              customer: element.name,
              sex: element.sex,
            };
            arr.push(item);
          });
        }
        this.feedbacks = arr;
      }
    },

    async getLastEvents() {
      this.eventData1 = [];
      this.eventData2 = [];

      const result = await requestApi('home.getLastEvents');

      if (result?.data?.forEach) {
        result.data.forEach((element, i) => {
          const item = {
            id: element.id,
            pic: element.cover,
            category: element.category,
            title: element.title,
          };
          if (i < 6) {
            this.eventData1.push(item);
          } else {
            this.eventData2.push(item);
          }
        });
      }
    },
  },
  mounted() {
    this.getBanners();
    this.getLastNews();
    this.getQuestionnaires();
    this.getLastEvents();
  },
};
</script>
